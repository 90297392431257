import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { EProvider } from 'Common/consts';
import { logger } from 'Common/utils/logger/logger';
export const getState = (rootState) => rootState.datasets;
export const getSelectedDatasetId = createSelector(getState, ({ selectedDatasetId }) => selectedDatasetId);
const getDatasets = createSelector(getState, ({ datasets }) => datasets);
const getProviders = createSelector(getState, ({ providers }) => providers);
const getDatasetsLists = createSelector(getState, ({ datasetsLists }) => datasetsLists);
const getProvidersList = createSelector(getState, ({ providersList }) => providersList);
export const getLabel = createSelector([
    getSelectedDatasetId,
    getDatasets,
    getProviders,
], (selectedDatasetId, datasets, providers) => {
    if (!selectedDatasetId) {
        return undefined;
    }
    const selectedDataset = datasets[selectedDatasetId];
    const selectedProvider = providers[selectedDataset.providerId];
    const isDatasetNameStartsWithProviderName = selectedDataset.name.startsWith(selectedProvider.name);
    const datasetLabel = isDatasetNameStartsWithProviderName
        ? `${selectedDataset.name}`
        : `${selectedProvider.name} ${selectedDataset.name}`;
    return datasetLabel;
});
export const getData = createSelector([
    getSelectedDatasetId,
    getDatasets,
    getProviders,
    getDatasetsLists,
    getProvidersList,
], (selectedDatasetId, datasets, providers, datasetsLists, providersList) => providersList.map((providerId) => {
    let isSelected = false;
    const provider = providers[providerId];
    const providerDatasets = datasetsLists[providerId].map((datasetId) => {
        const dataset = datasets[datasetId];
        if (dataset.id === selectedDatasetId) {
            isSelected = true;
        }
        return dataset;
    });
    return Object.assign(Object.assign({}, provider), { datasets: providerDatasets, isSelected });
}));
export const getIsLoading = createSelector(getState, ({ isLoading }) => isLoading);
export const getSelectedDataset = createSelector([getSelectedDatasetId, getDatasets], (selectedDatasetId, datasets) => (selectedDatasetId ? datasets[selectedDatasetId] : null));
export const getSelectedDatasetStatus = createSelector([getIsLoading, getSelectedDataset], (isLoading, selectedDataset) => ({
    isLoading,
    selectedDataset,
}));
export const getSelectedDatasetProviderId = createSelector(getSelectedDataset, (selectedDataset) => {
    var _a;
    const id = (_a = selectedDataset === null || selectedDataset === void 0 ? void 0 : selectedDataset.providerId) !== null && _a !== void 0 ? _a : null;
    if (id === null)
        return null;
    switch (id) {
        case EProvider.FinnGen: return EProvider.FinnGen;
        case EProvider.UKB: return EProvider.UKB;
        default: {
            logger.warn(`Unsupported provider selected: ${id}`);
            return null;
        }
    }
});
export const useControlLabel = () => useSelector(getLabel);
export const useControlData = () => useSelector(getData);
export const useIsLoading = () => useSelector(getIsLoading);
export const useSelectedDatasetProviderId = () => useSelector(getSelectedDatasetProviderId);
export const useSelectedDatasetId = () => useSelector(getSelectedDatasetId);
