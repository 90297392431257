var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { DASHBOARD_PVALUE_THRESHOLD } from 'Common/consts';
import { CollapsingModelsService } from 'Common/services';
import { isVariantName } from 'Common/utils';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { CollapsingModels, Datasets, Variant, VariantId, } from 'API/resources';
import { ActionError } from 'Store/errors';
import { setDataExists, setLoadingMessage } from './common';
export const typePrefix = 'VariantDashboard/fetchAPIData';
export const PARAMS = [
    {
        levelResult: ELevelResult.Variant,
        traitsType: ETraitsTypes.Binary,
    },
    {
        levelResult: ELevelResult.Variant,
        traitsType: ETraitsTypes.Continuous,
    },
];
export const fetchAPIData = createAsyncThunk(typePrefix, ({ variantId: variantIdParam, }, { dispatch, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        let variantId = variantIdParam;
        if (isVariantName(variantIdParam)) {
            dispatch(setLoadingMessage('Loading variant id...'));
            const variantIdResponse = yield VariantId.resource
                .getData({ variantName: variantIdParam }, undefined, undefined, signal);
            variantId = variantIdResponse.variant_id;
        }
        dispatch(setLoadingMessage('Loading datasets...'));
        const datasetsResponse = yield Datasets
            .getResource(2 /* Variant */)
            .getData({ resourceId: variantId }, undefined, undefined, signal);
        if (!datasetsResponse.length) {
            throw new HTTPNotFoundError();
        }
        const datasetsParams = datasetsResponse.map((dataset) => dataset.version_id);
        dispatch(setLoadingMessage('Loading collapsing models...'));
        const collapsingModelsData = yield Promise.all(datasetsParams.map((datasetVersionId) => __awaiter(void 0, void 0, void 0, function* () {
            const models = yield CollapsingModels
                .resource
                .getData({ datasetVersionId }, undefined, undefined, signal);
            return (models === null || models === void 0 ? void 0 : models.length) && { [datasetVersionId]: models };
        })));
        const nonEmptyCollapsingModelsData = collapsingModelsData
            .filter(Boolean);
        dispatch(setDataExists(true));
        if (!nonEmptyCollapsingModelsData.length) {
            throw new HTTPNotFoundError();
        }
        const collapsingModelsCollection = nonEmptyCollapsingModelsData
            .reduce((acc, curr) => (Object.assign(Object.assign({}, acc), curr)), {});
        const aggregatedCollapsingModelsData = Object.values(collapsingModelsCollection)
            .reduce((acc, curr) => [...acc, ...curr], []);
        const variantRequestParams = datasetsResponse.reduce((acc, curr) => {
            PARAMS.forEach((item) => {
                acc.push(Object.assign(Object.assign({}, item), { datasetVersionId: curr.version_id }));
            });
            return acc;
        }, []);
        dispatch(setLoadingMessage('Loading associations...'));
        let variantData = [];
        const variantDataRequest = (withThreshold = true) => () => Promise.all(variantRequestParams.map(({ datasetVersionId, levelResult, traitsType }) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield Variant.resource(levelResult).getData({
                datasetVersionId,
                variantId,
                traitsType,
            }, {
                collapsingModelId: CollapsingModelsService.getCollapsingModelIds(collapsingModelsCollection[datasetVersionId], levelResult),
                maxPValue: withThreshold ? DASHBOARD_PVALUE_THRESHOLD : undefined,
            }, undefined, signal);
            return {
                response,
                datasetVersionId,
                levelResult,
                traitsType,
            };
        })));
        const thresholdedVariantDataRequest = variantDataRequest();
        const fullVariantDataRequest = variantDataRequest(false);
        const thresholdedVariantData = yield thresholdedVariantDataRequest();
        const nonEmptyThresholdedVariantData = thresholdedVariantData.filter(({ response }) => response !== null);
        variantData = nonEmptyThresholdedVariantData;
        if (!nonEmptyThresholdedVariantData.length) {
            variantData = yield fullVariantDataRequest();
        }
        const nonEmptyVariantData = variantData.filter(({ response }) => response !== null);
        if (!nonEmptyVariantData.length) {
            throw new HTTPNotFoundError();
        }
        return {
            variantId,
            variantData: nonEmptyVariantData,
            datasets: datasetsResponse,
            collapsingModelsData: aggregatedCollapsingModelsData,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized());
    }
}));
