import { ETraitsTypes } from 'Shared/types';
import { GeneService, PhenotypeService, CollapsingModelService, } from 'Common/services';
import { ELoadingStatus } from 'API/types';
import { SORT_BY_VARIANT } from '../components/Table/consts';
export const getInitialState = () => ({
    isFiltering: false,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    filters: {
        variants: null,
        consequenceTypes: [],
    },
    consequenceTypes: [],
    domains: [],
    MTRData: [],
    mtrLoadingStatus: ELoadingStatus.Init,
    mtrErrorType: null,
    proteinLength: 0,
    datasets: [],
    datasetVersionId: '',
    gene: GeneService.empty,
    phenotype: PhenotypeService.empty,
    collapsingModel: CollapsingModelService.empty,
    phenotypicCategory: null,
    pvalue: null,
    traitsType: ETraitsTypes.Binary,
    tableSettings: {
        pageSize: 10,
        sortBy: SORT_BY_VARIANT,
    },
    transcriptId: '',
    variants: {
        collection: {},
        order: [],
    },
    filteredVariantsIds: [],
    phenotypeStats: {},
});
