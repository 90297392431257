var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { HTTPClientError, HTTPServerError, HTTPNotFoundError } from 'API/errors';
import { QQ } from 'API/resources';
import { ActionError } from 'Store/errors';
export const cleanupQQData = createAction('qqView/cleanupQQData');
export const setQQDataLoadingMessage = createAction('qqView/setQQDataLoadingMessage');
export const fetchQQAPIData = createAsyncThunk('qqView/fetchQQAPIData', (APIDataParams, { rejectWithValue, signal }) => __awaiter(void 0, void 0, void 0, function* () {
    const { datasetVersionId, phenotypeId, collapsingModelId, } = APIDataParams;
    setQQDataLoadingMessage('Loading QQ data...');
    try {
        const qqData = yield QQ.resource.getData({
            datasetVersionId,
            collapsingModelId,
        }, { phenotypeId }, undefined, signal);
        return {
            qqData,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
