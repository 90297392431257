/**
   * Gets whole collapsing models if `isOnlyLowestPValue` equal `false` otherwise
   * gets filtered collapsing models with lowest p-value
   *
   * @param collapsingModels - whole collapsing models from response
   * @param modelsIds - array of models ids with lowest p-value
   * @param isOnlyLowestPValue - flag indicating which array of collapsing models we want to get
   * @returns array of collapsing models
   */
export const getCollapsingModels = (collapsingModels, modelsIds, isOnlyLowestPValue) => (isOnlyLowestPValue
    ? collapsingModels.filter((model) => modelsIds.has(model.id))
    : collapsingModels);
