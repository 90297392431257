var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { CollapsingModelsService } from 'Common/services';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { DASHBOARD_PVALUE_THRESHOLD } from 'Common/consts';
import { Gene, GeneTolerance, CollapsingModels, Datasets, ExternalVariantSources, Transcripts, } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { ActionError } from 'Store/errors';
import { setDataExists, setLoadingMessage } from './common';
export const typePrefix = 'geneDashboardView/fetchAPIData';
export const PARAMS = [
    {
        levelResult: ELevelResult.Gene,
        traitsType: ETraitsTypes.Binary,
    },
    {
        levelResult: ELevelResult.Gene,
        traitsType: ETraitsTypes.Continuous,
    },
    {
        levelResult: ELevelResult.Variant,
        traitsType: ETraitsTypes.Binary,
    },
    {
        levelResult: ELevelResult.Variant,
        traitsType: ETraitsTypes.Continuous,
    },
];
export var ProvidersToFilter;
(function (ProvidersToFilter) {
    ProvidersToFilter["EVA"] = "eva";
})(ProvidersToFilter || (ProvidersToFilter = {}));
const fetchTranscripts = (geneId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    let transcriptsData = {
        proteinLength: 0,
        transcriptId: '',
        geneId: '',
        pfamDomains: [],
    };
    try {
        transcriptsData = yield Transcripts.resource.getData({ geneId }, undefined, undefined, signal);
    }
    catch (err) {
        if (err instanceof HTTPNotFoundError) {
            logger.warn(`No transcript data was fetched for gene ${geneId}.`);
        }
        else {
            throw err;
        }
    }
    return transcriptsData;
});
const fetchExternalVariantSourcesStats = (geneId, signal) => __awaiter(void 0, void 0, void 0, function* () {
    let data = {
        clin_var: { total: null, confident: null },
        hgmd: { total: null, confident: null },
        ebi_gwas: { total: null, confident: null },
        gbmi: { total: null, confident: null },
        omim: { total: null },
    };
    let hasError = false;
    try {
        /* todo: GeneDashboardV2 - this is mocked data, remove it when feature is testable */
        // data = externalVariantSourcesStatsResponseMock;
        data = yield ExternalVariantSources.resource.externalVariantSources
            .getData({ geneId }, undefined, undefined, signal);
    }
    catch (err) {
        hasError = true;
        logger.warn(`Couldn't fetch external variant sources data for gene ${geneId}.`);
    }
    return {
        data,
        hasError,
    };
});
export const fetchAPIData = createAsyncThunk(typePrefix, ({ geneName, }, { rejectWithValue, dispatch, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(setLoadingMessage('Loading datasets...'));
        const datasetsResponse = (yield Datasets
            .getResource(0 /* Gene */)
            .getData({ resourceId: geneName }, undefined, undefined, signal));
        const { baseDatasets, externalVariantSourcesDatasets, } = datasetsResponse.reduce((result, currentDataset) => {
            if (currentDataset.provider === ProvidersToFilter.EVA) {
                result.externalVariantSourcesDatasets.push(currentDataset);
            }
            else {
                result.baseDatasets.push(currentDataset);
            }
            return result;
        }, {
            baseDatasets: [],
            externalVariantSourcesDatasets: [],
        });
        if (!baseDatasets.length) {
            throw new HTTPNotFoundError();
        }
        const geneRequestParams = baseDatasets.reduce((acc, curr) => {
            PARAMS.forEach((item) => {
                acc.push(Object.assign(Object.assign({}, item), { datasetVersionId: curr.version_id }));
            });
            return acc;
        }, []);
        const datasetsParams = baseDatasets.map((dataset) => dataset.version_id);
        dispatch(setLoadingMessage('Loading collapsing models...'));
        const collapsingModelsData = yield Promise.all(datasetsParams.map((datasetVersionId) => __awaiter(void 0, void 0, void 0, function* () {
            const models = yield CollapsingModels
                .resource
                .getData({ datasetVersionId }, undefined, undefined, signal);
            return (models === null || models === void 0 ? void 0 : models.length) && { [datasetVersionId]: models };
        })));
        dispatch(setDataExists(true));
        const nonEmptyCollapsingModelsData = collapsingModelsData
            .filter(Boolean);
        if (!nonEmptyCollapsingModelsData.length) {
            throw new HTTPNotFoundError();
        }
        const collapsingModelsCollection = nonEmptyCollapsingModelsData
            .reduce((acc, curr) => (Object.assign(Object.assign({}, acc), curr)), {});
        const aggregatedCollapsingModelsData = Object.values(collapsingModelsCollection)
            .reduce((acc, curr) => [...acc, ...curr], []);
        dispatch(setLoadingMessage('Loading gene tolerance...'));
        const geneTolerance = yield GeneTolerance.resource.getData({}, {
            geneName,
        }, undefined, signal);
        dispatch(setLoadingMessage('Loading associations...'));
        let geneData = [];
        const geneDataRequest = (withThreshold = true) => () => Promise.all(geneRequestParams.map(({ datasetVersionId, levelResult, traitsType }) => __awaiter(void 0, void 0, void 0, function* () {
            const collapsingModelsIds = CollapsingModelsService.getCollapsingModelIds(collapsingModelsCollection[datasetVersionId], levelResult);
            if (!collapsingModelsIds.length) {
                return {
                    response: null,
                    datasetVersionId,
                    levelResult,
                    traitsType,
                };
            }
            const response = yield Gene.resource(levelResult).getData({
                datasetVersionId,
                geneName,
                traitsType,
            }, {
                collapsingModelId: collapsingModelsIds,
                maxPValue: withThreshold ? DASHBOARD_PVALUE_THRESHOLD : undefined,
            }, undefined, signal);
            return {
                response,
                datasetVersionId,
                levelResult,
                traitsType,
            };
        })));
        const thresholdedGeneDataRequest = geneDataRequest();
        const fullGeneDataRequest = geneDataRequest(false);
        const thresholdedGeneData = yield thresholdedGeneDataRequest();
        const nonEmptyThresholdedGeneData = thresholdedGeneData.filter(({ response }) => response !== null);
        geneData = nonEmptyThresholdedGeneData;
        if (!nonEmptyThresholdedGeneData.length) {
            geneData = yield fullGeneDataRequest();
        }
        const nonEmptyGeneData = geneData.filter(({ response }) => response !== null);
        if (!nonEmptyGeneData.length) {
            throw new HTTPNotFoundError();
        }
        const transcriptsData = yield fetchTranscripts(geneName, signal);
        dispatch(setLoadingMessage('Loading clinically relevant variants and publicly available common variant associations...'));
        const externalVariantSourcesData = yield fetchExternalVariantSourcesStats(geneName, signal);
        return {
            geneName,
            geneTolerance,
            proteinLength: transcriptsData.proteinLength,
            transcriptId: transcriptsData.transcriptId,
            geneData: nonEmptyGeneData,
            datasets: baseDatasets,
            collapsingModelsData: aggregatedCollapsingModelsData,
            externalVariantSourcesData,
            externalVariantSourcesDatasets,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof ActionError) {
            return rejectWithValue(ActionError.createSerialized(err.type));
        }
        if (err instanceof HTTPClientError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
