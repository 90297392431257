import { ELoadingStatus } from 'API/types';
import { GeneService, GeneToleranceService } from 'Common/services';
export const getInitialState = () => ({
    dataExists: false,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    gene: GeneService.empty,
    geneEnsembleId: '',
    datasets: [],
    categories: [],
    associations: {
        collection: {},
        order: [],
    },
    proteinLength: 0,
    MTRData: [],
    mtrLoadingStatus: ELoadingStatus.Init,
    mtrErrorType: null,
    maxTickValues: {},
    transcriptId: '',
    geneTolerance: GeneToleranceService.empty,
    significantAssociations: [],
    significantIndicators: {},
    countedPhenotypes: {
        significantBinaryPhenotypesCount: 0,
        significantContinuousPhenotypesCount: 0,
        suggestiveBinaryPhenotypesCount: 0,
        suggestiveContinuousPhenotypesCount: 0,
    },
    externalVariantSources: {
        externalVariantSourcesDatasets: [],
        count: {
            omim: {
                total: null,
            },
            hgmd: {
                total: null,
                confident: null,
            },
            clinVar: {
                total: null,
                confident: null,
            },
            ebiGwas: {
                total: null,
                confident: null,
            },
            gbmi: {
                total: null,
                confident: null,
            },
        },
        hasError: false,
    },
});
