import { useState, useCallback, useMemo } from 'react';
import { PHENOTYPES_TO_REMOVE } from 'Search/consts/phenotypesToRemove';
import { ESelectedSuggestionsType, ESearchSuggestionsType, } from 'Search/types';
/**
 * Hook implements selector between multiple suggestions collections
 *
 * @param recentSuggestions - Recent suggestions collection
 * @param searchSuggestions - Search suggestions collection
 * @returns Public API for suggestions selector hook
 *  - Selected suggestions collection
 *  - Method which shows `Recent` suggestions
 *  - Method which shows `Search` suggestions
 *  - Method which hides any suggestions
 *  - Attribute which shows if any suggestions are visible
 *  - Attribute which shows if `Recent` suggestions are visible
 *  - Attribute which shows if `Search` suggestions are visible
 */
export function useSuggestionsSelector(recentSuggestions, searchSuggestions) {
    const [type, setType] = useState(ESelectedSuggestionsType.Null);
    // @todo remove filtering logic after reingestion of correct data to prod
    const getFilteredSuggestions = useCallback((suggestions) => {
        var _a;
        if (!FEATURE_FLAGS.PUBLIC_VERSION && suggestions[ESearchSuggestionsType.Phenotype]) {
            return Object.assign(Object.assign({}, suggestions), { [ESearchSuggestionsType.Phenotype]: (_a = suggestions[ESearchSuggestionsType.Phenotype]) === null || _a === void 0 ? void 0 : _a.filter((suggestion) => !PHENOTYPES_TO_REMOVE.includes(suggestion.name)) });
        }
        return suggestions;
    }, []);
    const suggestions = useMemo(() => {
        switch (type) {
            case ESelectedSuggestionsType.Recent:
                return recentSuggestions;
            case ESelectedSuggestionsType.Search:
                return getFilteredSuggestions(searchSuggestions);
            default:
                return null;
        }
    }, [
        type,
        recentSuggestions,
        searchSuggestions,
        getFilteredSuggestions,
    ]);
    /**
     * Shows `Search` suggestions
     */
    const showSearchSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Search) {
            setType(ESelectedSuggestionsType.Search);
        }
    }, [type]);
    /**
     * Shows `Recent` suggestions
     */
    const showRecentSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Recent) {
            setType(ESelectedSuggestionsType.Recent);
        }
    }, [type]);
    /**
     * Hides any suggestions
     */
    const hideSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Null) {
            setType(ESelectedSuggestionsType.Null);
        }
    }, [type]);
    const areSuggestionsVisible = useMemo(() => type !== ESelectedSuggestionsType.Null, [type]);
    const areRecentSuggestionsVisible = useMemo(() => type === ESelectedSuggestionsType.Recent, [
        type,
    ]);
    const areSearchSuggestionsVisible = useMemo(() => type === ESelectedSuggestionsType.Search, [
        type,
    ]);
    return {
        suggestions,
        showSearchSuggestions,
        showRecentSuggestions,
        hideSuggestions,
        areSuggestionsVisible,
        areRecentSuggestionsVisible,
        areSearchSuggestionsVisible,
    };
}
