import { logger } from 'Common/utils/logger/logger';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
export default new RESTResource(({ datasetVersionId }) => {
    var _a;
    const urlRoot = (_a = process.env.MTR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/${datasetVersionId}/mtr_scores`;
}, 'get', {
    geneId: 'gene_id',
    transcriptId: 'transcript_id',
}, (error) => {
    logger.warn(`Error fetching MTR data from service: ${error.message}. Empty data will be used instead`);
    return {
        transcript_id: '',
        mtr_scores: [],
    };
});
