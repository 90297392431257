import { ELevelResult } from 'Shared/types';
import { VariantViewRoute, VariantDashboardViewRoute } from 'Router/index';
import { ESearchSuggestionsType } from 'Search/types';
import { SearchSuggestion } from '../SearchSuggestion/SearchSuggestion';
/**
 * Basic class for VariantSuggestion
 *
 * @extends SearchSuggestion
 */
export class VariantSuggestion extends SearchSuggestion {
    /**
     * Navigate to variant view
     *
     * @param navigateTo navigation callback
     * @param referrerURI url of referrer
     * @param referrerData specific data for referrer
     */
    navigate(navigateTo, referrerURI, referrerData) {
        if (FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED
            && FEATURE_FLAGS.VARIANT_DASHBOARD_ENABLED
            && !FEATURE_FLAGS.PUBLIC_VERSION) {
            navigateTo(VariantDashboardViewRoute, {
                variantId: this.id,
            }, referrerURI, referrerData);
        }
        else {
            navigateTo(VariantViewRoute, {
                datasetVersionId: this.datasetVersionId,
                variantId: this.id,
                levelResult: ELevelResult.Variant,
            }, referrerURI, referrerData);
        }
    }
    /**
     * Creates instance of VariantSuggestion
     *
     * @param id - id for VariantSuggestion instance
     * @param name - name for VariantSuggestion instance
     * @param dataset - Dataset
     */
    constructor(id, name, dataset) {
        super(id, name, dataset, ESearchSuggestionsType.Variant);
    }
}
