import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { PhenotypeStatsService, PhenotypeService, PhenotypicCategoryService, } from 'Common/services';
import { ELoadingStatus } from 'API/types';
export const getInitialState = () => ({
    dataExists: false,
    isEmpty: true,
    loadingStatus: ELoadingStatus.Init,
    commonLoadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    commonErrorType: null,
    collapsingModels: [],
    traitsType: ETraitsTypes.Binary,
    stats: PhenotypeStatsService.empty,
    phenotype: PhenotypeService.empty,
    category: PhenotypicCategoryService.empty,
    [ELevelResult.Gene]: {
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationsIds: [],
        tableSettings: {
            sortBy: [
                {
                    id: 'pvalue',
                    desc: false,
                },
            ],
            pageSize: 10,
        },
        isFiltering: false,
        collapsingModels: [],
        genes: {},
        hasOppositeLevelResult: false,
        filters: {
            collapsingModels: [],
            maxPValue: null,
            gene: null,
            isLowestPValue: false,
            isDirectionOfEffectFilterActive: false,
        },
    },
    [ELevelResult.Variant]: {
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationsIds: [],
        tableSettings: {
            sortBy: [
                {
                    id: 'pvalue',
                    desc: false,
                },
            ],
            pageSize: 10,
        },
        isFiltering: false,
        collapsingModels: [],
        genes: {},
        variants: {},
        hasOppositeLevelResult: false,
        filters: {
            collapsingModels: [],
            consequenceTypes: [],
            maxPValue: null,
            gene: null,
            variant: null,
            isLowestPValue: false,
            isDirectionOfEffectFilterActive: false,
        },
    },
    [ELevelResult.Phenotype]: {
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationsIds: [],
        tableSettings: {
            sortBy: [
                {
                    id: 'pvalue',
                    desc: false,
                },
            ],
            pageSize: 10,
        },
        isFiltering: false,
        phenotypes: [],
        categories: [],
        filters: {
            maxPValue: null,
            phenotype: null,
            categories: [],
        },
    },
});
