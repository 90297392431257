var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { CollapsingModels, } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { getSelectedDatasetProviderId } from 'Datasets/store/selectors';
import { fetchAPIData as fetchDatasetsData } from 'Datasets/store/actions';
import { ActionError } from 'Store/errors';
import { COLLAPSING_MODELS_LOADING_MESSAGE, DATASETS_LOADING_MESSAGE, } from 'PhenotypeView/consts';
import { setDataExists, setLoadingMessage } from './common';
const typePrefix = 'phenotypeView/fetchAPIData';
export const fetchAPIData = createAsyncThunk(typePrefix, ({ phenotypeId, datasetVersionId, }, { dispatch, getState, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        dispatch(setLoadingMessage(DATASETS_LOADING_MESSAGE));
        const fetchDatasetsDataAction = dispatch(fetchDatasetsData({
            datasetType: 1 /* Phenotype */,
            resourceId: phenotypeId,
            datasetVersionId,
        }));
        /**
          * Instead of passing signal down to each action
          * abort them via callback invoked by the current signal
        */
        // eslint-disable-next-line no-param-reassign
        signal.onabort = () => {
            fetchDatasetsDataAction.abort();
        };
        const { payload: datasetsResult } = yield fetchDatasetsDataAction;
        const datasetsError = datasetsResult;
        if (datasetsError.type !== undefined) {
            throw new ActionError(datasetsError.type);
        }
        const selectedProviderId = getSelectedDatasetProviderId(getState());
        if (!selectedProviderId) {
            throw new ActionError(0 /* NotFound */);
        }
        dispatch(setLoadingMessage(COLLAPSING_MODELS_LOADING_MESSAGE));
        const collapsingModelsData = yield CollapsingModels
            .resource
            .getData({ datasetVersionId });
        if (!collapsingModelsData.length) {
            throw new HTTPNotFoundError();
        }
        dispatch(setDataExists(true));
        return {
            collapsingModels: collapsingModelsData,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof ActionError) {
            return rejectWithValue(ActionError.createSerialized(err.type));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized());
    }
}));
