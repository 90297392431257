import { createSlice, current } from '@reduxjs/toolkit';
import { ELoadingStatus } from 'API/types';
import { ETraitsTypes } from 'Shared/types';
import { ESortType } from 'Shared/components/Table';
import { VariantMetadataService, VariantAssociationService, TraitsTypeService, CollapsingModelsService, PhenotypicCategoryService, } from 'Common/services';
import { sortAssociations, sortByPhenotypicCategory, } from 'Common/utils';
import { SIGNIFICANT_ASSOCIATIONS_THRESHOLD } from 'Common/consts';
import { fetchAPIData } from 'VariantView/store/actions/fetchAPIData';
import { fetchTraitsData } from 'VariantView/store/actions/fetchTraitsData';
import { getFilteredData } from 'VariantView/store/utils';
import { getInitialState } from './initialState';
const initialState = getInitialState();
let worker;
const slice = createSlice({
    name: 'variantView',
    initialState,
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        setCollapsingModels(state, action) {
            state.collapsingModels = CollapsingModelsService.createCollection(action.payload);
        },
        setCollapsingModelIds(state, action) {
            state.collapsingModelIds = action.payload;
        },
        setLoadingMessage(state, action) {
            state.loadingMessage = action.payload;
        },
        cleanup(state) {
            const { loadingStatus, loadingMessage, errorType, significantIndicators, collapsingModels, collapsingModelIds, [ETraitsTypes.Binary]: binaryTraitsState, [ETraitsTypes.Continuous]: continuousTraitsState, } = initialState;
            state.significantIndicators = significantIndicators;
            state.collapsingModels = collapsingModels;
            state.collapsingModelIds = collapsingModelIds;
            state[ETraitsTypes.Binary] = binaryTraitsState;
            state[ETraitsTypes.Continuous] = continuousTraitsState;
            state.loadingStatus = loadingStatus;
            state.loadingMessage = loadingMessage;
            state.errorType = errorType;
        },
        initWorker(_, action) {
            worker = action.payload;
        },
        terminateWorker() {
            worker === null || worker === void 0 ? void 0 : worker.terminate();
            worker = null;
        },
        setFilters(state, action) {
            const { payload: { traitsType, phenotype, categories, collapsingModels, maxPValue, isLowestPValue, isDirectionOfEffectFilterActive, }, } = action;
            state[traitsType].isFiltering = true;
            if (phenotype !== undefined) {
                state[traitsType].filters.phenotype = phenotype;
            }
            if (maxPValue !== undefined) {
                state[traitsType].filters.maxPValue = maxPValue;
            }
            if (categories !== undefined) {
                state[traitsType].filters.categories = categories;
            }
            if (collapsingModels !== undefined) {
                state[traitsType].filters.collapsingModels = collapsingModels;
            }
            if (isLowestPValue !== undefined) {
                state[traitsType].filters.isLowestPValue = isLowestPValue;
            }
            if (isDirectionOfEffectFilterActive !== undefined) {
                state[traitsType].filters.isDirectionOfEffectFilterActive = isDirectionOfEffectFilterActive;
            }
            const { associations, filters, phenotypes, } = current(state[traitsType]);
            if (worker) {
                worker.postMessage({
                    traitsType,
                    associations,
                    filters,
                    phenotypes,
                });
            }
            else {
                const filteredAssociations = getFilteredData({
                    associations,
                    filters,
                    phenotypes,
                });
                state[traitsType].filteredAssociationIds = filteredAssociations;
                state[traitsType].isFiltering = false;
            }
        },
        setTablePageSize(state, action) {
            const { traitsType, pageSize, } = action.payload;
            state[traitsType].tableSettings.pageSize = pageSize;
        },
        setTableSortBy(state, action) {
            const { traitsType, columnId, sortType, } = action.payload;
            const sortBy = []; // TODO to migrate that logic to table related adapter
            if (sortType !== ESortType.None) {
                sortBy.push({
                    id: columnId,
                    desc: sortType === ESortType.Desc,
                });
            }
            state[traitsType].tableSettings.sortBy = sortBy;
        },
        updateFilteredData(state, action) {
            const { payload: { traitsType, filteredAssociationIds, }, } = action;
            state[traitsType].filteredAssociationIds = filteredAssociationIds;
            state[traitsType].isFiltering = false;
        },
        setData(state, action) {
            const { payload: { traitsType, variantData, associations: variantAssociations, oppositeTraitsAssosications, }, } = action;
            const traits = TraitsTypeService.create(traitsType);
            const oppositeTraits = TraitsTypeService.getOppositeTraits(traits);
            const viewState = state[traits];
            state.traitsType = traits;
            const collapsingModelsCollection = current(state).collapsingModels;
            if (!variantData) {
                return;
            }
            const variantMetadata = variantData
                ? VariantMetadataService.create(variantData, Object.keys(variantData.associations)[0])
                : VariantMetadataService.empty;
            viewState.details.variantMetadata = variantMetadata;
            viewState.associations.collection = {};
            viewState.associations.order = [];
            viewState.filteredAssociationIds = [];
            const associationsCollection = {};
            const oppositeTraitsAssociationsCollection = {};
            const phenotypicCategoriesCollection = {};
            const collapsingModels = [];
            const phenotypes = {};
            Object.entries(variantAssociations).forEach(([id, associations]) => {
                if (!associations.length) {
                    return;
                }
                const collapsingModel = collapsingModelsCollection[id];
                collapsingModels.push(collapsingModel);
                associations.forEach((association) => {
                    const { category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, phenotype_name: phenotypeName, } = association;
                    if (!phenotypicCategoriesCollection[phenotypicCategoryName]) {
                        phenotypicCategoriesCollection[phenotypicCategoryName] = (PhenotypicCategoryService.create(phenotypicCategoryName, phenotypicCategoryShortName, phenotypicCategoryId));
                    }
                    const variantAssociation = VariantAssociationService.create(association, collapsingModel, variantMetadata);
                    const { pvalue, phenotype } = variantAssociation;
                    if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD) {
                        associationsCollection[phenotype.id] = phenotype.id;
                    }
                    if (phenotypeName) {
                        const currentModel = phenotypes[phenotypeName];
                        if (!currentModel
                            || (currentModel.pvalue === pvalue
                                && currentModel.collapsingModel.name.localeCompare(collapsingModel.name) === 1)
                            || currentModel.pvalue > pvalue) {
                            phenotypes[phenotypeName] = { collapsingModel, pvalue };
                        }
                    }
                    viewState.associations.collection[variantAssociation.id] = variantAssociation;
                    viewState.associations.order.push(variantAssociation.id);
                });
            });
            Object.keys(oppositeTraitsAssosications).forEach((id) => {
                const associations = oppositeTraitsAssosications[id];
                if (!associations.length) {
                    return;
                }
                associations.forEach((association) => {
                    oppositeTraitsAssociationsCollection[association.phenotype_id] = association.phenotype_id;
                });
            });
            if (!state.significantIndicators[traits]
                && !state.significantIndicators[oppositeTraits]) {
                state.significantIndicators[traits] = Object.values(associationsCollection).length;
                state.significantIndicators[oppositeTraits] = Object.values(oppositeTraitsAssociationsCollection).length;
            }
            sortAssociations(viewState.associations);
            const phenotypicCategories = Object
                .values(phenotypicCategoriesCollection)
                .sort(sortByPhenotypicCategory);
            Object.keys(phenotypes).forEach((phenotypeName) => {
                viewState.phenotypes[phenotypeName] = phenotypes[phenotypeName].collapsingModel.id;
            });
            viewState.collapsingModels = CollapsingModelsService.getSortedByName(collapsingModels);
            viewState.categories = phenotypicCategories;
            viewState.filters.categories = phenotypicCategories;
            viewState.filters.collapsingModels = viewState.collapsingModels;
            viewState.filteredAssociationIds = getFilteredData({
                associations: viewState.associations,
                filters: viewState.filters,
                phenotypes: viewState.phenotypes,
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = 'Loading datasets...';
        })
            .addCase(fetchAPIData.fulfilled, (state) => {
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchTraitsData.pending, (state, action) => {
            const { traitsType } = action.meta.arg;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            traitsState.loadingStatus = ELoadingStatus.Loading;
            traitsState.loadingMessage = `Loading ${traits} associations...`;
        })
            .addCase(fetchTraitsData.fulfilled, (state, action) => {
            const { traitsType } = action.meta.arg;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            traitsState.loadingStatus = ELoadingStatus.Finished;
            traitsState.loadingMessage = '';
        })
            .addCase(fetchTraitsData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { traitsType } = action.meta.arg;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            const { type: errorType } = action.payload;
            traitsState.errorType = errorType;
            traitsState.loadingStatus = ELoadingStatus.Finished;
            traitsState.loadingMessage = '';
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup, initWorker, terminateWorker, setFilters, setTablePageSize, setTableSortBy, updateFilteredData, } = slice.actions;
export default slice.reducer;
