import { createSelector } from '@reduxjs/toolkit';
import { getTableMapper, getPlotDataFromCategories, getCollapsingModels as getCollapsingModelsData, } from 'VariantView/utils';
import { getValidatedPlotData } from 'Common/utils';
import { getState } from './common';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTraitsSelectors = (traitsType) => {
    const getTraitsState = createSelector([getState], (state) => state[traitsType]);
    const getErrorType = createSelector([getTraitsState], ({ errorType }) => errorType);
    const getLoadingStatus = createSelector([getTraitsState], ({ loadingStatus }) => loadingStatus);
    const getLoadingMessage = createSelector([getTraitsState], ({ loadingMessage }) => loadingMessage);
    const getIsFiltering = createSelector([getTraitsState], ({ isFiltering }) => isFiltering);
    const getIsEmpty = createSelector([getTraitsState], ({ associations }) => associations.order.length === 0);
    const getDetails = createSelector([getTraitsState], ({ details }) => details);
    const getVariantMetadata = createSelector([getDetails], ({ variantMetadata }) => variantMetadata);
    const getVariantId = createSelector([getVariantMetadata], ({ variant: { id: variantId } }) => variantId);
    const getVariantName = createSelector([getVariantMetadata], ({ variant: { name: variantName } }) => variantName);
    const getFilteredAssociationIds = createSelector([getTraitsState], ({ filteredAssociationIds }) => filteredAssociationIds);
    const getAssociations = createSelector([getTraitsState], ({ associations }) => associations);
    const getFilters = createSelector([getTraitsState], ({ filters }) => filters);
    const getTableData = createSelector([getAssociations, getFilteredAssociationIds], (associations, filteredAssociationIds) => (filteredAssociationIds.map((id) => associations.collection[id])));
    const getAllTableData = createSelector([getAssociations], ({ collection }) => Object.values(collection));
    const getDataPointsByPhenotypicCategoriesMapper = createSelector([getTableData], (tableData) => getTableMapper(getValidatedPlotData(tableData)));
    const getAllDataPointsByPhenotypicCategoriesMapper = createSelector([getAllTableData], (tableData) => getTableMapper(getValidatedPlotData(tableData)));
    const getFilteredCategories = createSelector(getFilters, ({ categories }) => categories);
    const getPlotData = createSelector([getFilteredCategories, getDataPointsByPhenotypicCategoriesMapper], (categories, dataPointsMapper) => getPlotDataFromCategories(categories, dataPointsMapper));
    const getCategories = createSelector(getTraitsState, ({ categories }) => categories);
    const getAllPlotData = createSelector([getCategories, getAllDataPointsByPhenotypicCategoriesMapper], (categories, dataPointsMapper) => getPlotDataFromCategories(categories, dataPointsMapper));
    const getTableSettings = createSelector([getTraitsState], ({ tableSettings }) => tableSettings);
    const getPhenotypes = createSelector([getTraitsState], ({ phenotypes }) => phenotypes);
    const getPhenotypesNames = createSelector(getPhenotypes, (phenotypes) => Object.keys(phenotypes));
    const getModelsIdsWithLowestPValue = createSelector(getPhenotypes, (phenotypes) => new Set(Object.values(phenotypes)));
    const getCollapsingModels = createSelector(getTraitsState, ({ collapsingModels }) => collapsingModels);
    const getIsLowestPValue = createSelector(getFilters, ({ isLowestPValue }) => isLowestPValue);
    const getFilteredCollapsingModels = createSelector(getCollapsingModels, getModelsIdsWithLowestPValue, getIsLowestPValue, (collapsingModels, modelsIds, isLowestPValue) => (getCollapsingModelsData(collapsingModels, modelsIds, isLowestPValue)));
    const getFiltersData = createSelector([
        getIsFiltering,
        getFilters,
        getPhenotypesNames,
        getCategories,
        getFilteredCollapsingModels,
        getModelsIdsWithLowestPValue,
    ], (isFiltering, filters, phenotypes, categories, collapsingModels, modelsIdsWithLowestPValue) => ({
        disabled: isFiltering,
        filters,
        phenotypes,
        categories,
        collapsingModels,
        modelsIdsWithLowestPValue,
    }));
    return {
        getLoadingStatus,
        getLoadingMessage,
        getIsFiltering,
        getIsEmpty,
        getErrorType,
        getDetails,
        getVariantId,
        getVariantName,
        getVariantMetadata,
        getPlotData,
        getAllPlotData,
        getTableData,
        getAllTableData,
        getTableSettings,
        getFiltersData,
    };
};
