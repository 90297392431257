import { v4 as uuidv4 } from 'uuid';
import { escapeRegExp } from 'Shared/utils';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { PhenotypeService } from 'Common/services/Phenotype';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
/**
 * Class service with static methods for work with GeneAssociation
 */
export class GeneAssociationService {
    /**
     * Get a base properties for all kinds of GeneAssociation
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns BaseProperties object
     */
    static createBaseProperties(associationData, gene, collapsingModel, datasetVersionId = '', id = uuidv4()) {
        const pvalue = parseFloat(associationData.pvalue);
        return {
            id,
            gene,
            phenotype: PhenotypeService.create(associationData.phenotype_name, associationData.phenotype_id),
            phenotypicCategory: PhenotypicCategoryService.create(associationData.category_name, associationData.category_short_name, associationData.category_id),
            collapsingModel,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            datasetVersionId,
        };
    }
    /**
     * Gets an boolean value which identify does association have GLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isGLR(association) {
        var _a;
        const associationVLRCandidate = association;
        return ((_a = associationVLRCandidate.variant) !== null && _a !== void 0 ? _a : null) === null;
    }
    /**
     * Gets an boolean value which identify does association have VLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isVLR(association) {
        return !GeneAssociationService.isGLR(association);
    }
    /**
     * Gets variants unique names array
     *
     * @static
     * @param collection - collection of associations
     * @returns An array of variants names
     */
    static getVariantsNames(collection) {
        const variants = Object.values(collection).reduce((acc, association) => {
            if (GeneAssociationService.isVLR(association)) {
                acc.push(association.variant.name);
            }
            return acc;
        }, []);
        return Array.from(new Set(variants));
    }
    /**
     * Returns array of association Ids based on provided filters
     *
     * @param o.associations - Associations collection and so
     * @param o.filters - Applied filters
     * @returns Updated (filtered) data Ids
     */
    static getFilteredData({ associations, filters, phenotypes, }) {
        const { collapsingModels, consequenceTypes, categories, maxPValue, phenotype, variant, isLowestPValue, isDirectionOfEffectFilterActive, } = filters;
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const selectedCategoriesNames = new Set(categories.map((category) => category.name));
        const selectedConsequenceTypes = new Set(consequenceTypes);
        let filteredAssociations = associations.order
            .map((id) => associations.collection[id])
            .filter((association) => {
            if (!isLowestPValue)
                return true;
            if (GeneAssociationService.isGLR(association)) {
                return phenotypes[association.phenotype.name] === association.collapsingModel.id;
            }
            const variantName = association.variant.name;
            const collapsingModelId = (phenotypes[association.phenotype.name][variantName]);
            return collapsingModelId === association.collapsingModel.id;
        })
            .filter((association) => {
            if (!isDirectionOfEffectFilterActive)
                return true;
            const { traitsType } = association;
            if (traitsType === ETraitsTypes.Binary) {
                const { oddsRatio } = association;
                return oddsRatio !== null && oddsRatio < 1;
            }
            if (traitsType === ETraitsTypes.Continuous) {
                const { levelResult } = association;
                if (levelResult === ELevelResult.Gene) {
                    const { beta } = association;
                    return beta !== null && beta < 0;
                }
                if (levelResult === ELevelResult.Variant) {
                    const { effectSize } = association;
                    return effectSize !== null && effectSize < 0;
                }
                return false;
            }
            return false;
        })
            .filter(({ collapsingModel }) => selectedCollapsingModelIds.has(collapsingModel.id))
            .filter(({ phenotypicCategory }) => selectedCategoriesNames.has(phenotypicCategory.name));
        if (phenotype !== null) {
            filteredAssociations = filteredAssociations.filter((association) => new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
        }
        if (variant !== null) {
            filteredAssociations = filteredAssociations.filter((association) => {
                if (GeneAssociationService.isGLR(association)) {
                    return false;
                }
                return new RegExp(escapeRegExp(variant), 'i').test(association.variant.name);
            });
        }
        if (maxPValue !== null) {
            filteredAssociations = filteredAssociations.filter(({ pvalue }) => pvalue <= maxPValue);
        }
        if (consequenceTypes) {
            filteredAssociations = filteredAssociations.filter((association) => {
                if (GeneAssociationService.isGLR(association)) {
                    return true;
                }
                return association.consequenceType == null
                    || selectedConsequenceTypes.has(association.consequenceType);
            });
        }
        return filteredAssociations.map(({ id }) => id);
    }
    /**
     * Gets whole collapsing models if `isOnlyLowestPValue` equal `false` otherwise
     * gets filtered collapsing models with lowest p-value
     *
     * @param collapsingModels - whole collapsing models from response
     * @param modelsIds - array of models ids with lowest p-value
     * @param isOnlyLowestPValue - flag indicating which array of collapsing models we want to get
     * @returns array of collapsing models
     */
    static getCollapsingModels(collapsingModels, modelsIds, isOnlyLowestPValue) {
        return isOnlyLowestPValue
            ? collapsingModels.filter((model) => modelsIds.has(model.id))
            : collapsingModels;
    }
    /**
     * Gets collapsingModelsIds only for associations with lowest p-value
     *
     * @param phenotypes - the mapping object with lowest p-value
     * @returns set with collapsingModelsIds
     */
    static getModelsIdsWithLowestPValue(phenotypes, levelResult) {
        let modelsIds = Object.values(phenotypes);
        if (levelResult === ELevelResult.Variant) {
            modelsIds = modelsIds.map((models) => Object.values(models)).flat();
        }
        return new Set(modelsIds);
    }
}
