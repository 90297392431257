import { ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService } from 'Common/services';
import { ELoadingStatus } from 'API/types';
export const getInitialState = () => ({
    dataExists: false,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    QQDataErrorType: null,
    QQDataLoadingStatus: ELoadingStatus.Loading,
    QQDataLoadingMessage: '',
    phenotype: {
        id: '',
        name: '',
    },
    traitsType: ETraitsTypes.Continuous,
    collapsingModels: CollapsingModelsService.empty,
    plotData: [],
    maxPlotValues: null,
    lambda: null,
    phenotypeStatistic: {},
});
