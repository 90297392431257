import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo, useCallback, } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import useRouter from 'Router/index';
import styles from './Link.css';
const Link = ({ to, children, title, className, referrerData, isTextFieldLink = false, isNavLink = false, target = '_self', }) => {
    const { getRoute, getRouteState } = useRouter();
    const { routeHistory, addToRouteHistory } = getRouteState();
    const { pathname = '', state } = useMemo(() => {
        if (typeof to === 'string') {
            return getRoute(to, referrerData);
        }
        return to;
    }, [to, getRoute, referrerData]);
    const handleStateUpdate = useCallback(() => {
        if (state === null || state === void 0 ? void 0 : state.referrerURI) {
            addToRouteHistory({
                referrerURI: state === null || state === void 0 ? void 0 : state.referrerURI,
                referrerData: state === null || state === void 0 ? void 0 : state.referrerData,
            });
        }
    }, [addToRouteHistory, state]);
    return (_jsx(RouterLink, Object.assign({ to: pathname, onClick: handleStateUpdate, state: { routeHistory }, title: title, className: classnames(className, styles.link, { [styles.textFieldLink]: isTextFieldLink }, { [styles.navLink]: isNavLink }), target: target }, { children: children }), void 0));
};
export default memo(Link);
