var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { MTR } from 'API/resources';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { ActionError } from 'Store/errors';
export const typePrefix = 'geneDashboardView/fetchMTRData';
export const fetchMTRData = createAsyncThunk(typePrefix, (APIDataParams, { rejectWithValue, signal }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { geneName, } = APIDataParams;
    try {
        const MTRData = yield MTR.resource.getData({ datasetVersionId: (_a = process.env.MTR_DATASET_VERSION_ID) !== null && _a !== void 0 ? _a : 'edd5b6b3-622d-418c-9313-572d154054cf' }, { geneId: geneName }, undefined, signal);
        return MTRData;
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));
