import { ETraitsTypes } from 'Shared/types';
import { filledArray, getRandomFloat } from 'Shared/utils';
import { collapsingModelIdMock, geneIdMock, geneNameMock, phenotypeIdMock, phenotypeNameMock, } from 'Common/mocks';
import { createMockedURL, getMock } from 'API/utils';
import resource from './resource';
import { UKBDatasetVersionId } from '../datasets';
import { collapsingModelsResponseMock } from '../collapsingModels';
const dotsQuantity = 100;
const getFilledArray = (maxPrecisionPerm) => filledArray(dotsQuantity, () => getRandomFloat(maxPrecisionPerm))
    .sort((a, b) => a - b);
const stats = {
    gene_name: geneNameMock,
    gene_id: geneIdMock,
    stats: {
        odds_ratio: 1,
        odds_ratio_lci: 19.386005571732,
        odds_ratio_uci: 812.163055858,
        qv_cases: 9164,
        qv_controls: 11064395488,
    },
};
export const getQuantiles = (min_p_perm, min_p_observed) => {
    const maxPrecisionPerm = Math.floor(-Math.log10(min_p_perm));
    const permPvalueArray = getFilledArray(maxPrecisionPerm);
    const pValueArray = getFilledArray(maxPrecisionPerm);
    pValueArray[0] = min_p_observed;
    return filledArray(dotsQuantity, (i) => (Object.assign({ p_perm: permPvalueArray[i], p_observed: pValueArray[i] }, stats)));
};
const response = {
    phenotype_name: phenotypeNameMock,
    phenotype_id: phenotypeIdMock,
    trait: ETraitsTypes.Binary,
    lambda_value: 0.9,
};
export const ptvallResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[0].name, model_id: collapsingModelsResponseMock[0].id, min_p_perm: 0.0001153, min_p_observed: 0.000218, quantiles: getQuantiles(0.0001153, 0.000218) });
const flexnonsynResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[1].name, model_id: collapsingModelsResponseMock[1].id, min_p_perm: 0.0001336, min_p_observed: 0.000004853, quantiles: getQuantiles(0.0001336, 0.000004853) });
const flexnonsynmtrResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[2].name, model_id: collapsingModelsResponseMock[2].id, min_p_perm: 0.0001895, min_p_observed: 0.0002234, quantiles: getQuantiles(0.0001895, 0.0002234) });
const ptvResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[3].name, model_id: collapsingModelsResponseMock[3].id, min_p_perm: 0.00006364, min_p_observed: 0.0004102, quantiles: getQuantiles(0.00006364, 0.0004102) });
const ptv5pcntResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[4].name, model_id: collapsingModelsResponseMock[4].id, min_p_perm: 0.0001153, min_p_observed: 0.000218, quantiles: getQuantiles(0.0001153, 0.000218) });
const synResponseMock = Object.assign(Object.assign({}, response), { model_name: collapsingModelsResponseMock[5].name, model_id: collapsingModelsResponseMock[5].id, min_p_perm: 0.0001905, min_p_observed: 0.0002367, quantiles: getQuantiles(0.0001905, 0.0002367) });
export const emptyResponse = {
    quantiles: [],
};
const quantileBaseResponseMock = {
    gene_name: geneNameMock,
    gene_id: geneIdMock,
    p_observed: 0.0005646,
    p_perm: 0.0001817,
    stats: {
        odds_ratio_lci: 2.112,
        odds_ratio_uci: 8.819,
    },
};
export const binaryQuantilesResponseMock = [
    Object.assign(Object.assign({}, quantileBaseResponseMock), { stats: Object.assign(Object.assign({}, quantileBaseResponseMock.stats), { odds_ratio: 4.3157, qv_cases: 9, qv_controls: 46 }) }),
    {
        gene_id: 'PCDHA1',
        gene_name: 'PCDHA1',
        p_observed: 0.0007375,
        p_perm: 0.0003701,
        stats: {
            odds_ratio: 2.9139,
            odds_ratio_lci: 1.6684,
            odds_ratio_uci: 5.0895,
            qv_cases: 14,
            qv_controls: 106,
        },
    },
    {
        gene_id: 'ZNF684',
        gene_name: 'ZNF684',
        p_observed: 0.0008969,
        p_perm: 0.0006363,
        stats: {
            odds_ratio: 6.3013,
            odds_ratio_lci: 2.5428,
            odds_ratio_uci: 15.6154,
            qv_cases: 6,
            qv_controls: 21,
        },
    },
    {
        gene_id: 'ALCAM',
        gene_name: 'ALCAM',
        p_observed: 0.0009144,
        p_perm: 0.0009144,
        stats: {
            odds_ratio: 12.6011,
            odds_ratio_lci: 3.6882,
            odds_ratio_uci: 43.0527,
            qv_cases: 4,
            qv_controls: 7,
        },
    },
    {
        gene_id: 'RASGRP1',
        gene_name: 'RASGRP1',
        p_observed: 0.001,
        p_perm: 0.0012,
        stats: {
            odds_ratio: 3.1144,
            odds_ratio_lci: 1.7012,
            odds_ratio_uci: 5.7017,
            qv_cases: 12,
            qv_controls: 85,
        },
    },
    {
        gene_id: 'DR1',
        gene_name: 'DR1',
        p_observed: 1.0,
        p_perm: 1.0,
        stats: null,
    },
];
export const continuousQuantilesResponseMock = [
    Object.assign(Object.assign({}, quantileBaseResponseMock), { stats: Object.assign(Object.assign({}, quantileBaseResponseMock.stats), { beta: 7, yes_qv: 10 }) }),
    {
        gene_id: 'DLL4',
        gene_name: 'DLL4',
        p_observed: 0.0007375,
        p_perm: 0.0003701,
        stats: {
            odds_ratio_lci: 1.6684,
            odds_ratio_uci: 5.0895,
            beta: 14,
            yes_qv: 106,
        },
    },
    {
        gene_id: 'USF2',
        gene_name: 'USF2',
        p_observed: 0.0008969,
        p_perm: 0.0006363,
        stats: {
            odds_ratio_lci: 2.5428,
            odds_ratio_uci: 15.6154,
            beta: 6,
            yes_qv: 21,
        },
    },
    {
        gene_id: 'USF1',
        gene_name: 'USF1',
        p_observed: 0.0009144,
        p_perm: 0.0009144,
        stats: {
            odds_ratio_lci: 3.6882,
            odds_ratio_uci: 43.0527,
            beta: 4,
            yes_qv: 7,
        },
    },
    {
        gene_id: 'DLK2',
        gene_name: 'DLK2',
        p_observed: 0.001,
        p_perm: 0.0012,
        stats: {
            odds_ratio_lci: 1.7012,
            odds_ratio_uci: 5.7017,
            beta: 12,
            yes_qv: 85,
        },
    },
    {
        gene_id: 'DLK1',
        gene_name: 'DLK1',
        p_observed: 1.0,
        p_perm: 1.0,
        stats: null,
    },
];
export const binaryQuantileResponseMock = (binaryQuantilesResponseMock[0]);
export const continuousQuantileResponseMock = (continuousQuantilesResponseMock[0]);
export const requestParamsUKB = {
    datasetVersionId: UKBDatasetVersionId,
    phenotypeId: phenotypeIdMock,
    collapsingModelId: collapsingModelIdMock,
};
const mockedURL = {
    [createMockedURL(resource, requestParamsUKB)]: {
        full: ptvallResponseMock,
        empty: emptyResponse,
    },
    [createMockedURL(resource, Object.assign(Object.assign({}, requestParamsUKB), { collapsingModelId: collapsingModelsResponseMock[1].id }))]: {
        full: flexnonsynResponseMock,
        empty: emptyResponse,
    },
    [createMockedURL(resource, Object.assign(Object.assign({}, requestParamsUKB), { collapsingModelId: collapsingModelsResponseMock[2].id }))]: {
        full: flexnonsynmtrResponseMock,
        empty: emptyResponse,
    },
    [createMockedURL(resource, Object.assign(Object.assign({}, requestParamsUKB), { collapsingModelId: collapsingModelsResponseMock[3].id }))]: {
        full: ptvResponseMock,
        empty: emptyResponse,
    },
    [createMockedURL(resource, Object.assign(Object.assign({}, requestParamsUKB), { collapsingModelId: collapsingModelsResponseMock[4].id }))]: {
        full: ptv5pcntResponseMock,
        empty: emptyResponse,
    },
    [createMockedURL(resource, Object.assign(Object.assign({}, requestParamsUKB), { collapsingModelId: collapsingModelsResponseMock[5].id }))]: {
        full: synResponseMock,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);
