import { ELevelResult } from 'Shared/types';
import { PhenotypeAssociationVLRService } from 'Common/services/PhenotypeAssociations';
/**
 * Class service with static methods for work with ContinuousAssociationVLR
 */
export class ContinuousAssociationVLRService {
    /**
     * Creates continuous phenotype association vlr object
     * @static
     * @param association - Original phenotype association data received from API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModel - Collapsing model object
     */
    static create(association, phenotypeData, collapsingModel) {
        const continuousAssociation = PhenotypeAssociationVLRService.createContinuousAssociation(association, phenotypeData, collapsingModel);
        const { effectSize, MAF } = continuousAssociation;
        return Object.assign(Object.assign({}, continuousAssociation), { levelResult: ELevelResult.Variant, coordinates: {
                x: Math.log10(MAF || 1),
                y: effectSize !== null && effectSize !== void 0 ? effectSize : 0,
            } });
    }
}
