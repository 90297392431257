import { escapeRegExp } from 'Shared/utils/escapeRegExp/escapeRegExp';
import { GPAManhattanPlotData, ManhattanPlotData, ManhattanPlotDataGroup } from 'Shared/plots/models/';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { ChromosomeService, ChromosomesService, } from 'Common/services';
import { logger } from 'Common/utils/logger/logger';
import { CHAPTER_NUMBER_PATTERN } from 'Common/consts';
import { DataPointAdapter, DataPointAdapterGPA } from 'PhenotypeView/models';
/**
 * Class service with static methods for work with Associations
 */
export class AssociationsService {
    /**
     * Gets phenotype associations by filteredIds for the table
     *
     * @static
     * @param collection - Collection of associations
     * @param filteredAssociationsIds - Array of filtered associations ids
     * @returns An array of phenotype associations
     */
    static getTableData(collection, filteredAssociationsIds) {
        return filteredAssociationsIds.map((id) => collection[id]);
    }
    /**
     * Gets whole collapsing models if `isOnlyLowestPValue` equal `false` otherwise
     * gets filtered collapsing models with lowest p-value
     *
     * @param collapsingModels - whole collapsing models from response
     * @param modelsIds - array of models ids with lowest p-value
     * @param isOnlyLowestPValue - flag indicating which array of collapsing models we want to get
     * @returns array of collapsing models
     */
    static getCollapsingModels(collapsingModels, modelsIds, isOnlyLowestPValue) {
        return isOnlyLowestPValue
            ? collapsingModels.filter((model) => modelsIds.has(model.id))
            : collapsingModels;
    }
    /**
     * Gets the data for the GPAManhattanPlot for GLR data
     *
     * @static
     * @param chromosomes - Chromosomes instance
     * @param filteredAssociationsIds - Array of filtered associations
     * @returns An array of phenotype associations
     */
    static getPlotDataGLR(chromosomes, filteredAssociations) {
        const filteredDataPointsCollection = filteredAssociations.reduce((acc, phenotypeAssociation) => {
            const updatedAcc = Object.assign({}, acc);
            const chromosomeId = ChromosomesService.getChromosomeId(chromosomes, phenotypeAssociation.gene.name);
            if (!chromosomeId) {
                logger.warn(`Chromosome id for gene ${phenotypeAssociation.gene.name} (${phenotypeAssociation.id}) was not found: skipping`);
                return updatedAcc;
            }
            if (!updatedAcc[chromosomeId]) {
                updatedAcc[chromosomeId] = [];
            }
            let genePosition = ChromosomesService.getGenePosition(chromosomes, phenotypeAssociation.gene.name);
            if (!genePosition) {
                logger.warn(`Gene position in chromosome for gene ${phenotypeAssociation.gene} (${phenotypeAssociation.id}) was not found: replacing with 0`);
                genePosition = 0;
            }
            updatedAcc[chromosomeId].push(new DataPointAdapterGPA(phenotypeAssociation, genePosition));
            return updatedAcc;
        }, {});
        return Object
            .entries(filteredDataPointsCollection)
            .reduce((plotDataAcc, [chromosomeId, filteredDataPoints]) => {
            var _a;
            const chromosome = ChromosomesService.getChromosome(chromosomes, chromosomeId);
            return plotDataAcc.addGroup(new ManhattanPlotDataGroup((_a = chromosome.label) !== null && _a !== void 0 ? _a : chromosomeId, chromosome.label, chromosome.length, filteredDataPoints, chromosomeId));
        }, GPAManhattanPlotData.empty);
    }
    /**
     * Gets the data for the ManhattanPlot for PLR data
     *
     * @static
     * @param categories - Array of filtered phenotypic category
     * @param associations - array of filtered associations PLR
     * @param traitsType - ETraitsType
     * @returns An array of phenotype associations
     */
    static getPlotDataPLR(categories, associations, traitsType) {
        const filteredDataPointsCollection = associations.reduce((acc, association) => {
            const { category: { id } } = association;
            const updatedAcc = Object.assign({}, acc);
            if (!updatedAcc[id]) {
                updatedAcc[id] = [];
            }
            updatedAcc[id].push(new DataPointAdapter(association));
            return updatedAcc;
        }, {});
        return categories.reduce((acc, category, index) => {
            const { name, shortName, id } = category;
            const groupId = id;
            let groupLabel = shortName !== null && shortName !== void 0 ? shortName : `${index + 1}`;
            let groupName = name;
            // Roman numeral for binary traits if no short label was provided
            if (traitsType !== ETraitsTypes.Continuous && !shortName) {
                const [findMatch, findLabel] = CHAPTER_NUMBER_PATTERN.exec(name) || [];
                if (findLabel) {
                    groupLabel = findLabel;
                }
                if (findMatch) {
                    groupName = name.replace(findMatch, '');
                }
            }
            if (filteredDataPointsCollection[id].length) {
                acc.addGroup(new ManhattanPlotDataGroup(groupName, groupLabel, filteredDataPointsCollection[id].length, filteredDataPointsCollection[id], groupId));
            }
            return acc;
        }, ManhattanPlotData.empty);
    }
    /**
     * Gets the data for the ManhattanPlot for VLR data
     *
     * @static
     * @param chromosomes - Chromosomes instance
     * @param associations - Array of filtered associations
     * @returns An array of phenotype associations
     */
    static getPlotDataVLR(chromosomes, associations) {
        const dataPointsCollection = associations.reduce((acc, phenotypeAssociation) => {
            const updatedAcc = Object.assign({}, acc);
            const variant = phenotypeAssociation.variant.name.split('-');
            const chromosomeId = ChromosomeService.getNumberFromId(variant[0]);
            if (!chromosomeId) {
                logger.warn(`Chromosome id for gene ${phenotypeAssociation.gene.name} (${phenotypeAssociation.id}) was not found: skipping`);
                return updatedAcc;
            }
            if (!updatedAcc[chromosomeId]) {
                updatedAcc[chromosomeId] = [];
            }
            let genePosition = parseFloat(variant[1]);
            if (!genePosition) {
                logger.warn(`Gene position in chromosome for gene ${phenotypeAssociation.gene} (${phenotypeAssociation.id}) was not found: replacing with 0`);
                genePosition = 0;
            }
            updatedAcc[chromosomeId].push(new DataPointAdapterGPA(phenotypeAssociation, genePosition));
            return updatedAcc;
        }, {});
        return Object
            .entries(dataPointsCollection)
            .reduce((plotDataAcc, [chromosomeId, filteredDataPoints]) => {
            var _a, _b;
            const chromosome = ChromosomesService.getChromosome(chromosomes, chromosomeId);
            if (chromosome) {
                return plotDataAcc.addGroup(new ManhattanPlotDataGroup((_a = chromosome.label) !== null && _a !== void 0 ? _a : chromosomeId, (_b = chromosome.label) !== null && _b !== void 0 ? _b : chromosomeId, chromosome.length, filteredDataPoints, chromosomeId));
            }
            return plotDataAcc;
        }, GPAManhattanPlotData.empty);
    }
    /**
     * Returns array of association ids based on provided filters
     *
     * @param associations - Associations collection
     * @param filters - Applied filters
     * @param levelResult - Level result tab
     * @returns Updated (filtered) data Ids
     */
    static getFilteredData({ associations, filters, levelResult, genes, variants, traitsType, }) {
        if (levelResult === ELevelResult.Gene) {
            return AssociationsService.getFilteredDataGLR(associations, filters, genes, traitsType);
        }
        if (levelResult === ELevelResult.Variant) {
            return AssociationsService.getFilteredDataVLR(associations, filters, variants);
        }
        if (levelResult === ELevelResult.Phenotype) {
            return AssociationsService.getFilteredDataPLR(associations, filters);
        }
        throw new Error('Cannot filter phenotype association, unsupported level result');
    }
    /**
     * Gets filtered associations ids for GLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @returns An array of filtered associations ids
     */
    static getFilteredDataGLR({ collection, order, }, { collapsingModels, maxPValue, gene, isLowestPValue, isDirectionOfEffectFilterActive, }, genes, traitsType) {
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const filterDirectionOfEffect = (association) => {
            if (!isDirectionOfEffectFilterActive)
                return true;
            if (traitsType === ETraitsTypes.Binary) {
                const { oddsRatio } = association;
                return oddsRatio !== null && oddsRatio < 1;
            }
            if (traitsType === ETraitsTypes.Continuous) {
                const { beta } = association;
                return beta !== null && beta < 0;
            }
            return false;
        };
        return order.filter((id) => {
            const association = collection[id];
            return (!isLowestPValue || genes[association.gene.name] === association.collapsingModel.id)
                && (filterDirectionOfEffect(association))
                && selectedCollapsingModelIds.has(association.collapsingModel.id)
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (gene === null || new RegExp(escapeRegExp(gene), 'i').test(association.gene.name));
        });
    }
    /**
     * Gets filtered associations ids for VLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @returns An array of filtered associations ids
     */
    static getFilteredDataVLR({ collection, order, }, { collapsingModels, consequenceTypes, maxPValue, gene, variant, isLowestPValue, isDirectionOfEffectFilterActive, }, variants) {
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const selectedConsequenceTypes = new Set(consequenceTypes);
        const filterDirectionOfEffect = (association) => {
            if (!isDirectionOfEffectFilterActive)
                return true;
            const { traitsType } = association;
            if (traitsType === ETraitsTypes.Binary) {
                const { oddsRatio } = association;
                return oddsRatio !== null && oddsRatio < 1;
            }
            if (traitsType === ETraitsTypes.Continuous) {
                const { effectSize } = association;
                return effectSize !== null && effectSize < 0;
            }
            return false;
        };
        return order.filter((id) => {
            const association = collection[id];
            return (!isLowestPValue || variants[association.variant.name] === association.collapsingModel.id)
                && (filterDirectionOfEffect(association))
                && selectedCollapsingModelIds.has(association.collapsingModel.id)
                && (association.consequenceType == null
                    || selectedConsequenceTypes.has(association.consequenceType))
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (gene === null || new RegExp(escapeRegExp(gene), 'i').test(association.gene.name))
                && (variant === null || new RegExp(escapeRegExp(variant), 'i').test(association.variant.name));
        });
    }
    /**
     * Gets filtered associations ids for PLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @returns An array of filtered associations ids
     */
    static getFilteredDataPLR({ collection, order, }, { categories, phenotype, maxPValue, }) {
        const selectedCategoriesIds = new Set(categories.map((category) => category.id));
        return order.filter((id) => {
            const association = collection[id];
            return selectedCategoriesIds.has(association.category.id)
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (phenotype === null || new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
        });
    }
}
