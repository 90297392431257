import { ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService, VariantMetadataService } from 'Common/services';
import { SIGNIFICANT_INDICATORS_INIT } from 'Common/components/IndicatorsTag';
import { ELoadingStatus } from 'API/types';
export const getInitialState = () => ({
    dataExists: false,
    errorType: null,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    collapsingModels: CollapsingModelsService.empty,
    collapsingModelIds: [],
    traitsType: ETraitsTypes.Binary,
    significantIndicators: SIGNIFICANT_INDICATORS_INIT,
    [ETraitsTypes.Binary]: {
        errorType: null,
        loadingStatus: ELoadingStatus.Init,
        loadingMessage: '',
        isFiltering: false,
        details: {
            variantMetadata: VariantMetadataService.empty,
        },
        filters: {
            collapsingModels: [],
            categories: [],
            phenotype: null,
            maxPValue: null,
            isLowestPValue: false,
            isDirectionOfEffectFilterActive: false,
        },
        collapsingModels: [],
        phenotypes: {},
        categories: [],
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationIds: [],
        tableSettings: {
            sortBy: [{ id: 'pvalue_binary', desc: false }],
            pageSize: 10,
        },
    },
    [ETraitsTypes.Continuous]: {
        errorType: null,
        loadingStatus: ELoadingStatus.Init,
        loadingMessage: '',
        isFiltering: false,
        details: {
            variantMetadata: VariantMetadataService.empty,
        },
        filters: {
            collapsingModels: [],
            categories: [],
            phenotype: null,
            maxPValue: null,
            isLowestPValue: false,
            isDirectionOfEffectFilterActive: false,
        },
        collapsingModels: [],
        phenotypes: {},
        categories: [],
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationIds: [],
        tableSettings: {
            sortBy: [{ id: 'pvalue_continuous', desc: false }],
            pageSize: 10,
        },
    },
});
