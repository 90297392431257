import { escapeRegExp } from 'Shared/utils';
import { ETraitsTypes } from 'Shared/types';
/**
 * Transform variant associations based on provided filters
 *
 * @param o - Params required for filtering
 * @param o.associations - Associations collection
 * @param o.filters - Applied filters
 * @returns Updated (filtered) data Ids
 */
export const getFilteredData = ({ associations, filters, phenotypes, }) => {
    const { collapsingModels, categories, phenotype, maxPValue, isLowestPValue, isDirectionOfEffectFilterActive, } = filters;
    const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
    const selectedCategoriesNames = new Set(categories.map((category) => category.name));
    let filteredAssociations = associations.order
        .map((id) => associations.collection[id])
        .filter((association) => (!isLowestPValue || phenotypes[association.phenotype.name] === association.collapsingModel.id))
        .filter((association) => {
        if (!isDirectionOfEffectFilterActive)
            return true;
        const { traitsType } = association;
        if (traitsType === ETraitsTypes.Binary) {
            const { oddsRatio } = association;
            return oddsRatio !== null && oddsRatio < 1;
        }
        if (traitsType === ETraitsTypes.Continuous) {
            const { effectSize } = association;
            return effectSize !== null && effectSize < 0;
        }
        return false;
    })
        .filter(({ collapsingModel }) => selectedCollapsingModelIds.has(collapsingModel.id))
        .filter(({ phenotypicCategory, }) => selectedCategoriesNames.has(phenotypicCategory.name));
    if (phenotype !== null) {
        filteredAssociations = filteredAssociations.filter((association) => new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
    }
    if (maxPValue !== null) {
        filteredAssociations = filteredAssociations.filter(({ pvalue }) => pvalue <= maxPValue);
    }
    return filteredAssociations.map(({ id }) => id);
};
