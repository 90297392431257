import { QuantileService } from './Quantile/Quantile.service';
import { BinaryQuantileService } from './BinaryQuantile/BinaryQuantile.service';
import { ContinuousQuantileService } from './ContinuousQuantile/ContinuousQuantile.service';
/**
 * Class service with static methods for work with Quantiles
 */
export class QuantilesService {
    /**
     * Gets an boolean value which identify does Quantile have binary type
     * @static
     * @param stats - quantile stats data
     * @returns boolean value
     */
    static isBinary(stats) {
        var _a, _b;
        const continuousQuantileStatsCandidate = stats;
        const firstNonMissingStats = (_b = (_a = continuousQuantileStatsCandidate.yes_qv) !== null && _a !== void 0 ? _a : continuousQuantileStatsCandidate.beta) !== null && _b !== void 0 ? _b : null;
        return firstNonMissingStats === null;
    }
    /**
     * Gets an boolean value which identify does Quantile have continuous type
     * @static
     * @param stats - quantile stats data
     * @returns boolean value
     */
    static isContinuous(stats) {
        return !QuantilesService.isBinary(stats);
    }
    /**
     * Gets the QQ plot data
     * @static
     * @param quantileData - quantile data
     * @returns Quantiles array
     */
    static getPlotData(quantilesData) {
        return quantilesData.map((quantile) => {
            if (!quantile.stats) {
                return QuantileService.create(quantile);
            }
            if (QuantilesService.isBinary(quantile.stats)) {
                return BinaryQuantileService.create(quantile);
            }
            return ContinuousQuantileService.create(quantile);
        });
    }
}
