import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect, useMemo, useRef, memo, createContext, } from 'react';
import classnames from 'classnames';
import { TextField, EInputTextType, InfoTooltip, ETooltipAction, Spinner, } from 'Shared/components';
import { EKeyCode } from 'Shared/consts';
import CloseIcon from 'Shared/assets/icons/tagClose.svg';
import DefaultSearchIcon from 'Shared/assets/icons/search.svg';
import spinnerStyles from 'Shared/components/Spinner/Spinner.css';
import useRouter from 'Router/index';
import { useIntersection } from 'Common/hooks';
import { DEFAULT_SUGGESTIONS_FETCH_DELAY } from 'Search/consts';
import { useSearchSuggestions, useSuggestionsSelector, useSuggestionsAdapter, useRecentSuggestions, } from 'Search/hooks';
import RecentSuggestions from '../RecentSuggestions/RecentSuggestions';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import { SearchSuggestionFactory } from './SearchSuggestionFactory';
import { INFO_ICON_TOOLTIP_TEXT, PLACEHOLDER_TEXT } from './consts';
import styles from './SearchBox.css';
export const Context = createContext({
    subscribe: () => new IntersectionObserver(() => { }),
    unsubscribe: () => new IntersectionObserver(() => { }),
});
const intersectionChecker = ({ target: { clientWidth, scrollWidth }, }) => (clientWidth < scrollWidth);
const SearchBox = (props) => {
    const { className, recentSuggestionsKey, placeholder = PLACEHOLDER_TEXT, fetchDelay = DEFAULT_SUGGESTIONS_FETCH_DELAY, 
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    searchIcon: SearchIcon = DefaultSearchIcon, recentSuggestionsEnabled: recentSuggestionsEnabledProp = true, hasInfoIcon = true, infoIconText = INFO_ICON_TOOLTIP_TEXT, referrerState, } = props;
    const [isFocused, setFocused] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const searchBox = useRef(null);
    const searchInput = useRef(null);
    const { navigateTo } = useRouter();
    const recentSuggestionsEnabled = FEATURE_FLAGS.RECENT_SUGGESTIONS_ENABLED
        && recentSuggestionsEnabledProp;
    const { searchSuggestions, searchSuggestionsAreLoaded, searchSuggestionsAreLoading, searchSuggestionsCanBeDisplayed, forbidSearchSuggestionsDisplay, } = useSearchSuggestions(searchValue, fetchDelay);
    const { recentSuggestions, addRecentSuggestion, removeRecentSuggestion, } = useRecentSuggestions(recentSuggestionsEnabled, recentSuggestionsKey);
    const { suggestions, showRecentSuggestions, showSearchSuggestions, hideSuggestions, areRecentSuggestionsVisible, areSearchSuggestionsVisible, } = useSuggestionsSelector(recentSuggestions, searchSuggestions.collection);
    const suggestionsAdapter = useSuggestionsAdapter(suggestions, searchSuggestions.order);
    if (searchSuggestionsAreLoaded
        && searchSuggestionsCanBeDisplayed
        && isFocused
        && !areSearchSuggestionsVisible) {
        showSearchSuggestions();
    }
    if (searchValue.trim().length === 0
        && isFocused
        && !areRecentSuggestionsVisible) {
        forbidSearchSuggestionsDisplay();
        if (recentSuggestionsEnabled) {
            showRecentSuggestions();
        }
        else {
            hideSuggestions();
        }
    }
    const isCloseButtonVisible = useMemo(() => (inputValue.length > 0
        && isFocused
        && !searchSuggestionsAreLoading), [inputValue, isFocused, searchSuggestionsAreLoading]);
    const isInfoIconVisible = useMemo(() => (hasInfoIcon && isFocused && !isCloseButtonVisible && !inputValue), [hasInfoIcon, isFocused, isCloseButtonVisible, inputValue]);
    const setInputSearchValue = useCallback((value) => {
        setSearchValue(value);
        setInputValue(value);
    }, []);
    const handleInputBlur = useCallback(() => {
        forbidSearchSuggestionsDisplay();
        setFocused(false);
        const searchInputElement = searchInput.current;
        if (searchInputElement) {
            searchInputElement.blur();
        }
    }, [searchInput, forbidSearchSuggestionsDisplay]);
    const handleNavigate = useCallback((suggestionsItem) => {
        addRecentSuggestion(suggestionsItem);
        setInputSearchValue(suggestionsItem.name);
        handleInputBlur();
        suggestionsItem.navigate(navigateTo, referrerState === null || referrerState === void 0 ? void 0 : referrerState.referrerURI, referrerState === null || referrerState === void 0 ? void 0 : referrerState.referrerData);
        setInputSearchValue('');
    }, [
        addRecentSuggestion,
        handleInputBlur,
        navigateTo,
        setInputSearchValue,
        referrerState,
    ]);
    const handleKeyDown = useCallback(({ code, ctrlKey, metaKey }) => {
        var _a;
        const isPasteCommand = (ctrlKey || metaKey) && code === EKeyCode.KeyV;
        if (suggestionsAdapter.isNull() && !isPasteCommand) {
            return;
        }
        let currentSuggestion;
        switch (code) {
            case EKeyCode.Enter: {
                if (searchSuggestionsAreLoading) {
                    return;
                }
                const currentItem = (_a = suggestionsAdapter.iterator.getCurrent()) !== null && _a !== void 0 ? _a : suggestionsAdapter.iterator.getFirst();
                if (currentItem) {
                    handleNavigate(currentItem);
                }
                break;
            }
            case EKeyCode.Esc:
                hideSuggestions();
                forbidSearchSuggestionsDisplay();
                if (searchValue !== inputValue) {
                    setInputValue(searchValue);
                }
                if (areRecentSuggestionsVisible) {
                    handleInputBlur();
                }
                break;
            case EKeyCode.DownArrow:
                currentSuggestion = suggestionsAdapter.iterator.next();
                if (currentSuggestion) {
                    setInputValue(currentSuggestion.name);
                }
                break;
            case EKeyCode.UpArrow:
                currentSuggestion = suggestionsAdapter.iterator.previous();
                if (currentSuggestion) {
                    setInputValue(currentSuggestion.name);
                }
                break;
            default:
        }
        if (isPasteCommand) {
            setFocused(true);
        }
    }, [
        areRecentSuggestionsVisible,
        forbidSearchSuggestionsDisplay,
        handleInputBlur,
        handleNavigate,
        hideSuggestions,
        inputValue,
        searchSuggestionsAreLoading,
        searchValue,
        suggestionsAdapter,
    ]);
    const handleFocus = useCallback(() => {
        setFocused(true);
    }, []);
    const handleChange = useCallback(({ target: { value } }) => {
        setFocused(true);
        setInputSearchValue(value);
    }, [setInputSearchValue]);
    const handleCloseIconClick = useCallback((event) => {
        event.stopPropagation();
        setInputSearchValue('');
        handleInputBlur();
    }, [setInputSearchValue, handleInputBlur]);
    const handleSuggestionClick = useCallback((suggestionsItem) => {
        handleNavigate(suggestionsItem);
    }, [handleNavigate]);
    const handleSuggestionMouseOver = useCallback((index) => {
        if (!suggestionsAdapter.isNull()) {
            suggestionsAdapter.iterator.setIndex(index);
        }
    }, [suggestionsAdapter]);
    const handleSuggestionMouseOut = useCallback(() => {
        if (!suggestionsAdapter.isNull()) {
            suggestionsAdapter.iterator.reset();
        }
    }, [suggestionsAdapter]);
    useEffect(() => {
        const onDocumentClick = (event) => {
            const clickTarget = event.target;
            const searchBoxElement = searchBox.current;
            if (searchBoxElement && !searchBoxElement.contains(clickTarget)) {
                setFocused(false);
                hideSuggestions();
            }
        };
        document.addEventListener('mousedown', onDocumentClick, true);
        return () => document.removeEventListener('mousedown', onDocumentClick);
    }, [hideSuggestions]);
    const layoutClassName = classnames(styles.layout, className, {
        [styles.focusSearchBoxWithClassname]: className && isFocused,
    });
    const { subscribe, unsubscribe, } = useIntersection(intersectionChecker);
    const phenotypeProps = useMemo(() => ({
        unsubscribe,
        subscribe,
    }), [
        unsubscribe,
        subscribe,
    ]);
    const renderSuggestion = useCallback((itemProps) => (_jsx(Context.Provider, Object.assign({ value: phenotypeProps }, { children: _jsx(SearchSuggestionFactory, Object.assign({ searchPattern: inputValue }, itemProps), void 0) }), void 0)), [inputValue, phenotypeProps]);
    return (_jsx("div", Object.assign({ className: layoutClassName, "data-testid": "searchBox" }, { children: _jsxs("div", Object.assign({ className: styles.box, ref: searchBox }, { children: [_jsxs("div", Object.assign({ className: styles.controls }, { children: [_jsx(SearchIcon, { className: classnames(styles.icon, styles.iconSearch) }, void 0), _jsx(TextField, { className: styles.input, ref: searchInput, type: EInputTextType.Search, value: inputValue, onFocus: handleFocus, onClick: handleFocus, onChange: handleChange, onKeyDown: handleKeyDown, "aria-label": placeholder, placeholder: placeholder, "data-testid": "SearchBox.input" }, void 0), searchSuggestionsAreLoading && (_jsx("div", Object.assign({ className: styles.spinner }, { children: _jsx(Spinner, { className: spinnerStyles.searchBoxSpinner, testId: "SearchBox.spinner" }, void 0) }), void 0)), isCloseButtonVisible && (_jsx(CloseIcon, { "data-testid": "SearchBox.closeIcon", className: classnames(styles.icon, styles.iconClose), onClick: handleCloseIconClick }, void 0)), !searchSuggestionsAreLoading && isInfoIconVisible && (_jsx(InfoTooltip, Object.assign({ id: "SearchInfoTooltip", targetEvent: ETooltipAction.Hover, overlayClassName: styles.infoTooltip, className: styles.infoIcon }, { children: infoIconText }), void 0))] }), void 0), isFocused
                    && areSearchSuggestionsVisible
                    && suggestionsAdapter.isSearch()
                    && (_jsx(SearchSuggestions, { suggestions: suggestionsAdapter.values, suggestionsIndex: suggestionsAdapter.iterator.index, suggestionsGroups: suggestionsAdapter.groups, onSuggestionClick: handleSuggestionClick, onSuggestionMouseOver: handleSuggestionMouseOver, onSuggestionMouseOut: handleSuggestionMouseOut, renderItem: renderSuggestion, className: className }, void 0)), isFocused
                    && areRecentSuggestionsVisible
                    && suggestionsAdapter.isRecent()
                    && (_jsx(RecentSuggestions, { suggestions: suggestionsAdapter.values, suggestionsIndex: suggestionsAdapter.iterator.index, suggestionsGroups: suggestionsAdapter.groups, onSuggestionClick: handleSuggestionClick, onSuggestionMouseOver: handleSuggestionMouseOver, onSuggestionMouseOut: handleSuggestionMouseOut, onRecentSuggestionRemove: removeRecentSuggestion }, void 0))] }), void 0) }), void 0));
};
export default memo(SearchBox);
