var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { PhenotypeStats, Phenotype, } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { ActionError } from 'Store/errors';
import { ASSOCIATIONS_PLR_LOADING_MESSAGE, } from 'PhenotypeView/consts';
import { setLoadingMessage } from './common';
import { getCollapsingModels, getIsEmpty } from '../selectors/common';
const typePrefix = 'phenotypeView/fetchPLRData';
export const fetchPLRData = createAsyncThunk(typePrefix, ({ levelResult, phenotypeId, datasetVersionId, }, { dispatch, getState, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const collapsingModelsData = getCollapsingModels(getState());
        dispatch(setLoadingMessage(ASSOCIATIONS_PLR_LOADING_MESSAGE));
        const phenotypeResource = Phenotype.resource(levelResult);
        const phenotypeRequest = phenotypeResource.getData({
            phenotypeId,
            datasetVersionId,
        }, {
            collapsingModelId: collapsingModelsData.map((collapsingModel) => collapsingModel.id),
        }, undefined, signal);
        const phenotypeStatisticsRequest = PhenotypeStats
            .resource(levelResult)
            .getData({
            phenotypeId,
            datasetVersionId,
        }, undefined, undefined, signal);
        const [stats, phenotypeResponse,] = yield Promise.all([
            phenotypeStatisticsRequest,
            phenotypeRequest,
        ]);
        return {
            phenotypeResponse,
            stats,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof ActionError) {
            return rejectWithValue(ActionError.createSerialized(err.type));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized());
    }
}), {
    condition: (_, { getState }) => {
        const state = getState();
        return !getIsEmpty(state);
    },
});
