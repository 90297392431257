import { geneMock } from 'Common/mocks';
import { binaryQuantilesResponseMock, continuousQuantilesResponseMock, } from 'API/resources/qq';
import { BinaryQuantileService } from '../BinaryQuantile/BinaryQuantile.service';
import { ContinuousQuantileService } from '../ContinuousQuantile/ContinuousQuantile.service';
export const baseQuantileMock = {
    id: 'testID_1',
    expectedPValueLog: -Math.log10(0.0001817),
    observedPValueLog: -Math.log10(0.0005646),
    gene: geneMock,
    pvalue: 0.0005646,
};
export const binaryQuantileMock = Object.assign(Object.assign({}, baseQuantileMock), { oddsRatio: 4.3157, oddsRatioLCI: 2.112, oddsRatioUCI: 8.819, QVCases: 9, QVControls: 46 });
export const continuousQuantileMock = Object.assign(Object.assign({}, baseQuantileMock), { beta: 7, yesQV: 10, LCI: 2.112, UCI: 8.819 });
export const binaryQuantilesMock = binaryQuantilesResponseMock
    .map((quantile) => BinaryQuantileService.create(quantile));
export const continuousQuantilesMock = continuousQuantilesResponseMock
    .map((quantile) => ContinuousQuantileService.create(quantile));
