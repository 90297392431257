import { v4 as uuidv4 } from 'uuid';
import { GeneService } from 'Common/services/Gene';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
/**
 * Class service with static methods for work with PhenotypeAssociationGLR
 */
export class PhenotypeAssociationGLRService {
    /**
     * Creates a base properties for GLR phenotype associations
     *
     * @static
     * @param association - response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - CollapsingModel object
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns base properties object
     */
    static createCommonProperties(association, phenotypeData, collapsingModel, id = uuidv4()) {
        const pvalue = parseFloat(association.pvalue);
        return {
            id,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            collapsingModel,
            gene: GeneService.create(association.gene_name, association.gene_id),
            phenotypicCategory: PhenotypicCategoryService.create(phenotypeData.category_name, phenotypeData.category_short_name, phenotypeData.category_id),
            samples: association.samples,
        };
    }
    /**
     * Creates a PhenotypeBinaryAssociationGLR object
     *
     * @static
     * @param association - binary response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - collapsingModel for phenotype association
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns PhenotypeBinaryAssociationGLR object
     */
    static createBinaryAssociation(association, phenotypeData, collapsingModel, id = uuidv4()) {
        const { stats } = association;
        return Object.assign(Object.assign({}, PhenotypeAssociationGLRService.createCommonProperties(association, phenotypeData, collapsingModel, id)), { samples: association.samples, cases: stats.cases, controls: stats.controls, QVCases: stats.qv_cases, caseFrequency: stats.case_frequency, QVControls: stats.qv_controls, controlFrequency: stats.control_frequency, oddsRatio: stats.odds_ratio, oddsRatioLCI: stats.odds_ratio_lci, oddsRatioUCI: stats.odds_ratio_uci });
    }
    /**
     * Creates a PhenotypeContinuousAssociationGLR object
     *
     * @static
     * @param association - continuous response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - collapsingModel for phenotype association
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns PhenotypeContinuousAssociationGLR object
     */
    static createContinuousAssociation(association, phenotypeData, collapsingModel, id) {
        const { stats } = association;
        return Object.assign(Object.assign({}, PhenotypeAssociationGLRService.createCommonProperties(association, phenotypeData, collapsingModel, id)), { samples: association.samples, yesQV: stats.yes_qv, noQV: stats.no_qv, caseMedian: stats.case_median, controlMedian: stats.control_median, beta: stats.beta, betaSTD: stats.beta_std, LCI: stats.odds_ratio_lci, UCI: stats.odds_ratio_uci });
    }
}
