/* eslint-disable no-param-reassign */
/* This util is for use inside *.slice.ts files only */
import { current } from '@reduxjs/toolkit';
import { ELevelResult } from 'Shared/types';
import { AssociationsService } from 'PhenotypeView/services';
export const handleFilteringGLR = (state, worker, traitsType) => {
    const { filters, associations, genes, } = state;
    if (worker) {
        worker.postMessage({
            filters: current(filters),
            associations: current(associations),
            genes: current(genes),
            levelResult: ELevelResult.Gene,
            traitsType,
        });
    }
    else {
        state.filteredAssociationsIds = AssociationsService.getFilteredDataGLR(associations, filters, genes, traitsType);
        state.isFiltering = false;
    }
};
