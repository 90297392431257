import { createSlice } from '@reduxjs/toolkit';
import { ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService, TraitsTypeService } from 'Common/services';
import { ELoadingStatus } from 'API/types';
import { AssociationsService } from 'FESView/services/Associations.service';
import { FES_PLOT_DATA_SIZE, DEFAULT_EDGE_COORDINATES } from 'FESView/consts';
import { getInitialState } from './initialState';
import { fetchAPIData } from './actions/fetchAPIData';
import { setLoadingMessage } from './actions/common';
export const initialState = getInitialState();
const fesView = createSlice({
    name: 'fesView',
    initialState,
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        cleanup(state) {
            const { loadingStatus, loadingMessage, errorType, phenotype, traitsType, plotData, plotSettings, phenotypeStats, dataExists, } = initialState;
            state.loadingMessage = loadingMessage;
            state.loadingStatus = loadingStatus;
            state.errorType = errorType;
            state.phenotype = phenotype;
            state.traitsType = traitsType;
            state.plotData = plotData;
            state.plotSettings = plotSettings;
            state.phenotypeStats = phenotypeStats;
            state.dataExists = dataExists;
            state.filters.maxPValue = null;
        },
        setMaxPValueFilter: (state, action) => {
            state.filters.maxPValue = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLoadingMessage, (state, action) => {
            state.loadingMessage = action.payload;
        })
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = 'Loading data...';
        })
            .addCase(fetchAPIData.fulfilled, (state, { payload }) => {
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
            const { phenotypeDataGLR, phenotypeDataVLR, collapsingModelsData, phenotypeStats, } = payload;
            const { phenotype_id: phenotypeId = '', phenotype_name: phenotypeName = '', traits_type: traitsType, } = phenotypeDataGLR;
            const traits = TraitsTypeService.create(traitsType, ETraitsTypes.Binary);
            state.traitsType = traits;
            state.phenotype = {
                id: phenotypeId,
                name: phenotypeName,
            };
            let plotData = AssociationsService.getPlotData(phenotypeDataGLR, phenotypeDataVLR, traits, CollapsingModelsService.createCollection(collapsingModelsData), FES_PLOT_DATA_SIZE);
            // Feature CGRPTL-3714
            plotData = AssociationsService.filterAssociations(plotData);
            const edgeCoordinates = AssociationsService.getEdgeCoordinates(plotData, DEFAULT_EDGE_COORDINATES);
            state.plotData = plotData;
            state.plotSettings = edgeCoordinates;
            state.phenotypeStats = {
                cases: phenotypeStats.cases,
                controls: phenotypeStats.controls,
                participants: phenotypeStats.participants,
            };
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup, setMaxPValueFilter, } = fesView.actions;
export default fesView.reducer;
