import { ETraitsTypes } from 'Shared/types';
import { geneNameMock, variantIdMock, variantNameMock, variantTypeMock, phenotypeNameMock, phenotypeIdMock, phenotypicCategoryIdMock, phenotypicCategoryNameMock, phenotypicCategoryShortNameMock, } from 'Common/mocks';
import { generateData, createMockedURL, getMock } from 'API/utils';
import { FinnGenDatasetVersionId, GenesResponseDatasetVersionIdMock, UBKWesDatasetVersionId, UBKWgsDatasetVersionId, UKBDatasetVersionId, } from '../datasets';
import { collapsingModelsResponseMock } from '../collapsingModels/mocks';
import { geneResourceGLR, geneResourceVLR } from './resource';
// Collections
export const geneBinaryCollectionGLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_name: phenotypeNameMock,
            phenotype_id: phenotypeIdMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 0.84,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 9153,
                qv_controls: 11064395477,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV of the genitourinary system',
            category_short_name: 'Genitourinary system',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 1.84,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 9153,
                qv_controls: 11064395477,
            },
        },
        {
            phenotype_name: '41202#D280#D28.0 Vulva',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_name: 'Chapter XIII Diseases of the musculoskeletal system and connective tissue',
            category_short_name: 'Musculoskeletal system',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 1,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 1,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_name: 'Chapter IX Diseases of the eye and adnexa',
            category_short_name: 'Eye diseases',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 2.84,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_name: 'Chapter XIII Diseases of the musculos system and connective tissue',
            category_short_name: 'Musculos system',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 0.4672230261,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_name: 'Chapter XVII Congenital malformations| deform d chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 467223026.84,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 9153,
                qv_controls: 11064395477,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826024',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826024',
            category_name: 'Chapter I Certa ious and parasitic diseases',
            category_short_name: 'Certa ious',
            pvalue: '0.00002269089747366948',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 261.84,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.0001315641053375005',
            samples: 7591,
            stats: {
                cases: 716,
                controls: 716,
                case_frequency: 0.9919231,
                control_frequency: 0.65725163,
                odds_ratio: 0.384,
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                qv_cases: 5123,
                qv_controls: 85564395000,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const geneContinuousCollectionGLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_name: phenotypeNameMock,
            phenotype_id: phenotypeIdMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            samples: 7591,
            stats: {
                case_median: 787427063.86,
                control_median: 217199,
                beta: -83.5801,
                beta_std: 4665.84810524,
                odds_ratio_lci: 4701.661203,
                odds_ratio_uci: 5933433904.67,
                yes_qv: 4665,
                no_qv: 4701,
            },
        },
        {
            phenotype_name: '41202#C52#C52 Malignant neoplasm of vagina',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000016787940839440017',
            samples: 6307,
            stats: {
                case_median: 6146.42812,
                control_median: 40228157979647.5,
                beta: 568841476072.393,
                beta_std: 8.32551187,
                odds_ratio_lci: 7756142011396.98,
                odds_ratio_uci: 65.6112921526227,
                yes_qv: 32551187,
                no_qv: 6146,
            },
        },
        {
            phenotype_name: '41202#C52#C52 Malignant neoplasm of vagina',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.00020058721523753318',
            samples: 906,
            stats: {
                case_median: 41710255775059,
                control_median: 195735693269,
                beta: -62060.5210105265,
                beta_std: 6737500.66789378,
                odds_ratio_lci: 5,
                odds_ratio_uci: 99148447.1436,
                yes_qv: 62060,
                no_qv: 1436,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: '41202#M2325#M23.25 Derangement of meniscus due to old tear or injury (Posterior horn of lateral meniscus)',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826017',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826017',
            category_name: 'Chapter VI Diseases of the nervous system',
            category_short_name: 'Nervous system',
            pvalue: '0.00020058721523753318',
            samples: 906,
            stats: {
                case_median: 41710255775059,
                control_median: 195735693269,
                beta: 0,
                beta_std: 6737500.66789378,
                odds_ratio_lci: 5,
                odds_ratio_uci: 99148447.1436,
                yes_qv: 62060,
                no_qv: 1436,
            },
        },
        {
            phenotype_name: '41202#R568#R56.8 Other and unspecified convulsions',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826016',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826016',
            category_name: 'Chapter II Neoplasms',
            category_short_name: 'Neoplasms',
            pvalue: '0.00020058721523753318',
            samples: 906,
            stats: {
                case_median: 41710255775059,
                control_median: 195735693269,
                beta: -62060.5210105265,
                beta_std: 6737500.66789378,
                odds_ratio_lci: 5,
                odds_ratio_uci: 99148447.1436,
                yes_qv: 62060,
                no_qv: 1436,
            },
        },
        {
            phenotype_name: '41202#H048#H04.8 Other disorders of lachrymal system',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826015',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826015',
            category_name: 'Chapter XVII Congenital malformations| deformations and chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00020058721523753318',
            samples: 906,
            stats: {
                case_median: 41710255775059,
                control_median: 195735693269,
                beta: 62060.5210105265,
                beta_std: 6737500.66789378,
                odds_ratio_lci: 5,
                odds_ratio_uci: 99148447.1436,
                yes_qv: 62060,
                no_qv: 1436,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: '41202#Z37#Z37 Outcome of delivery',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826014',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826014',
            category_name: 'Chapter XIII Diseases of the musculoskeletal system and connective tissue',
            category_short_name: 'Musculoskeletal system',
            pvalue: '0.00009484063166357525',
            samples: 9888,
            stats: {
                case_median: 492.47292586,
                control_median: 173980461.656456,
                beta: 0,
                beta_std: 95961096780.9585,
                odds_ratio_lci: 587317705607.5,
                odds_ratio_uci: 476192251.985,
                yes_qv: 476192251,
                no_qv: 98864,
            },
        },
        {
            phenotype_name: '41202#BlockM45-M49#M45-M49 Spondylopathies',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826013',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826013',
            category_name: 'Chapter XVII Congenital malformations| deformations and chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00039117809431874926',
            samples: 5500,
            stats: {
                case_median: 337.6886580398,
                control_median: 7170.4139294,
                beta: -914032.33944638,
                beta_std: 26477.311,
                odds_ratio_lci: 841.955043,
                odds_ratio_uci: 3158.43270532,
                yes_qv: 3158,
                no_qv: 26477,
            },
        },
        {
            phenotype_name: '41202#M7925#M79.25 Neuralgia and neuritis| unspecified (Pelvic region and thigh)',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826012',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826012',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00040428079308479247',
            samples: 6874,
            stats: {
                case_median: 97564923.64473,
                control_median: 1.397068878,
                beta: 1805645342.58,
                beta_std: 252513.569,
                odds_ratio_lci: 184837,
                odds_ratio_uci: 131926.1,
                yes_qv: 131926,
                no_qv: 184837,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const geneBinaryCollectionVLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_name: phenotypeNameMock,
            phenotype_id: phenotypeIdMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            variant_id: variantIdMock,
            variant_type: variantTypeMock,
            variant_name: variantNameMock,
            stats: {
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                consequence_type: 'stop_gained',
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.12,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV of the genitourinary system',
            category_short_name: 'Genitourinary system',
            pvalue: '0.000151570655403801',
            variant_id: '505865de-00f0-424e-90e4-1386a9f8e341',
            variant_name: 'X-30720991-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#C508#C50.8 Overlapping lesion of breast',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'Genitourinary system',
            pvalue: '0.000151570655403801',
            variant_id: '255f7ebb-6b1a-4e2a-aaa5-baaffadc7c7c',
            variant_name: 'X-67546514-TGGCGGCGGCGGC-T',
            variant_type: 'indel',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.121231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00004409555296362999',
            variant_id: '4f870743-6e1a-4c30-a170-ef031280c02a',
            variant_name: 'X-67546514-TGGCGGCGGCGGCGGCGGCGGCGGCGGCGGCGGCGGCGGC-T',
            variant_type: 'indel',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.1231231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_name: 'Chapter IX Diseases of the eye and adnexa',
            category_short_name: 'Eye diseases',
            pvalue: '0.00006461442104416903',
            variant_id: 'd0592d4f-faac-4ca2-af22-97f9b8fa468b',
            variant_name: 'X-67546514-TGGCGGCGGCGGCGGCGGC-T',
            variant_type: 'indel',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_name: 'Chapter XIII Diseases of the musculos system and connective tissue',
            category_short_name: 'Musculos system',
            pvalue: '0.00027233931376009936',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 12.31,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_name: 'Chapter XVII Congenital malformations| deform d chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00007264052064847259',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 12.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826024',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826024',
            category_name: 'Chapter I Certa ious and parasitic diseases',
            category_short_name: 'Certa ious',
            pvalue: '0.00008104254110792741',
            variant_id: '6fe8423b-d59f-4abb-a891-d28d36535ecc',
            variant_name: 'X-67546514-TGGCGGCGGCGGCGGCGGCGGC-T',
            variant_type: 'indel',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 12.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.0001315641053375005',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 12.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const geneContinuousCollectionVLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_name: phenotypeNameMock,
            phenotype_id: phenotypeIdMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            variant_id: variantIdMock,
            variant_name: variantNameMock,
            variant_type: variantTypeMock,
            stats: {
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                consequence_type: 'stop_gained',
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
            },
        },
        {
            phenotype_name: '41202#C52#C52 Malignant neoplasm of vagina',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.000016787940839440017',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#C52#C52 Malignant neoplasm of vagina',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826021',
            category_name: 'Chapter XIV Diseases of the genitourinary system',
            category_short_name: 'FinnGen Genitourinary system',
            pvalue: '0.00020058721523753318',
            variant_id: '255f7ebb-6b1a-4e2a-aaa5-baaffadc7c7c',
            variant_name: 'X-67546514-TGGCGGCGGCGGC-T',
            variant_type: 'indel',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 0,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: '41202#M2325#M23.25 Derangement of meniscus due to old tear or injury (Posterior horn of lateral meniscus)',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826017',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826017',
            category_name: 'Chapter VI Diseases of the nervous system',
            category_short_name: 'Nervous system',
            pvalue: '0.000017763088531994597',
            variant_id: '29c6637e-73dd-4daa-a9d4-180c1238e907',
            variant_name: 'X-67721909-C-G',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#R568#R56.8 Other and unspecified convulsions',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826016',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826016',
            category_name: 'Chapter II Neoplasms',
            category_short_name: 'Neoplasms',
            pvalue: '0.00014055352318918855',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#H048#H04.8 Other disorders of lachrymal system',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826015',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826015',
            category_name: 'Chapter XVII Congenital malformations| deformations and chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.0001706196132176885',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 0,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: '41202#C52#C52 Malignant neoplasm of vagina',
            phenotype_id: '41202#Z37#Z37 Outcome of delivery',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826014',
            category_name: 'Chapter XIII Diseases of the musculoskeletal system and connective tissue',
            category_short_name: 'Musculoskeletal system',
            pvalue: '0.00009484063166357525',
            variant_id: '29c6637e-73dd-4daa-a9d4-180c1238e907',
            variant_name: 'X-67721909-C-G',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#BlockM45-M49#M45-M49 Spondylopathies',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826013',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826013',
            category_name: 'Chapter XVII Congenital malformations| deformations and chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00039117809431874926',
            variant_id: '29c6637e-73dd-4daa-a9d4-180c1238e907',
            variant_name: 'X-67721909-C-G',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: -54,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            phenotype_name: '41202#M7925#M79.25 Neuralgia and neuritis| unspecified (Pelvic region and thigh)',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826012',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826012',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00040428079308479247',
            variant_id: '59d84b2d-6cc7-4668-8415-d89171c465b9',
            variant_name: 'X-67545785-G-A',
            variant_type: 'snv',
            stats: {
                no_samples: 12300,
                no_aa_genotypes: 100,
                no_ab_genotypes: 200,
                no_bb_genotypes: 300,
                maf: 13.33,
                ab_or_bb_genotypes: 14.34,
                bb_genotypes: 44.44,
                effect_size: 0,
                effect_size_standard_error: 100,
                effect_size_lci: 20.22,
                effect_size_uci: 23.23,
                consequence_type: 'stop_gained',
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
// Associations
export const geneBinaryAssociationGLRResponseMock = (geneBinaryCollectionGLRResponseMock[collapsingModelsResponseMock[0].id][0]);
export const geneContinuousAssociationGLRResponseMock = (geneContinuousCollectionGLRResponseMock[collapsingModelsResponseMock[0].id][0]);
export const geneBinaryAssociationVLRResponseMock = (geneBinaryCollectionVLRResponseMock[collapsingModelsResponseMock[0].id][0]);
export const geneContinuousAssociationVLRResponseMock = (geneContinuousCollectionVLRResponseMock[collapsingModelsResponseMock[0].id][0]);
// Response
export const geneDataResponseMock = {
    gene_id: geneNameMock,
    gene_name: geneNameMock,
    traits_type: ETraitsTypes.Binary,
};
export const getEmptyGeneResponse = (traits_type = ETraitsTypes.Binary) => (Object.assign(Object.assign({}, geneDataResponseMock), { traits_type, associations: {} }));
export const geneBinaryGLRResponseMock = (Object.assign(Object.assign({}, getEmptyGeneResponse()), { associations: geneBinaryCollectionGLRResponseMock }));
export const geneContinuousGLRResponseMock = (Object.assign(Object.assign({}, getEmptyGeneResponse(ETraitsTypes.Continuous)), { associations: geneContinuousCollectionGLRResponseMock }));
export const geneBinaryVLRResponseMock = (Object.assign(Object.assign({}, getEmptyGeneResponse()), { associations: geneBinaryCollectionVLRResponseMock }));
export const geneContinuousVLRResponseMock = (Object.assign(Object.assign({}, getEmptyGeneResponse(ETraitsTypes.Continuous)), { associations: geneContinuousCollectionVLRResponseMock }));
// Fetch mocks
export const FinnGenBinaryRequestParams = {
    datasetVersionId: FinnGenDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const FinnGenContinuousRequestParams = {
    datasetVersionId: FinnGenDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Continuous,
};
export const UKBBinaryRequestParams = {
    datasetVersionId: UKBDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const UKBWesBinaryRequestParams = {
    datasetVersionId: UBKWesDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const UKBWgsBinaryRequestParams = {
    datasetVersionId: UBKWgsDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const UKBWesContinuousRequestParams = {
    datasetVersionId: UBKWesDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Continuous,
};
export const UKBWgsContinuousRequestParams = {
    datasetVersionId: UBKWgsDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Continuous,
};
export const UKBContinuousRequestParams = {
    datasetVersionId: UKBDatasetVersionId,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Continuous,
};
export const UKB300BinaryRequestParams = {
    datasetVersionId: GenesResponseDatasetVersionIdMock,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Binary,
};
export const UKB300ContinuousRequestParams = {
    datasetVersionId: GenesResponseDatasetVersionIdMock,
    geneName: geneNameMock,
    traitsType: ETraitsTypes.Continuous,
};
const mockedURL = {
    // Gene level result
    [createMockedURL(geneResourceGLR, FinnGenBinaryRequestParams)]: {
        full: geneBinaryGLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceGLR, FinnGenContinuousRequestParams)]: {
        full: geneContinuousGLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
    [createMockedURL(geneResourceGLR, UKBBinaryRequestParams)]: {
        full: geneBinaryGLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceGLR, UKBContinuousRequestParams)]: {
        full: geneContinuousGLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
    [createMockedURL(geneResourceGLR, UKB300BinaryRequestParams)]: {
        full: geneBinaryGLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceGLR, UKB300ContinuousRequestParams)]: {
        full: geneContinuousGLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
    // Variant level result
    [createMockedURL(geneResourceVLR, FinnGenBinaryRequestParams)]: {
        full: geneBinaryVLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceVLR, FinnGenContinuousRequestParams)]: {
        full: geneContinuousVLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
    [createMockedURL(geneResourceVLR, UKBBinaryRequestParams)]: {
        full: geneBinaryVLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceVLR, UKBContinuousRequestParams)]: {
        full: geneContinuousVLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
    [createMockedURL(geneResourceVLR, UKB300BinaryRequestParams)]: {
        full: geneBinaryVLRResponseMock,
        empty: getEmptyGeneResponse(),
    },
    [createMockedURL(geneResourceVLR, UKB300ContinuousRequestParams)]: {
        full: geneContinuousVLRResponseMock,
        empty: getEmptyGeneResponse(ETraitsTypes.Continuous),
    },
};
export const fixtures = getMock(mockedURL);
