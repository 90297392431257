// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutView__content__1sCrR{line-height:1.5rem;margin-top:0;padding:1.875rem 5rem 1.5rem}.AboutView__aboutMainTitle__iR4PJ{margin-top:2.375rem;padding-left:5rem}.AboutView__aboutTitle__2wXrb{font-size:1.1rem;margin-bottom:.5rem;margin-top:2rem}.AboutView__subHeader__1GSA4{height:3.125rem;padding:.75rem 5rem}.AboutView__publicationName__3n5Kl{font-weight:700}.AboutView__paragraph__1h1z4{margin:.5rem 0}", "",{"version":3,"sources":["webpack://./src/views/about/components/AboutView.css"],"names":[],"mappings":"AAAA,2BAEE,kBAAmB,CACnB,YAAa,CAFb,4BAGF,CAEA,kCACE,mBAAoB,CACpB,iBACF,CAEA,8BACE,gBAAiB,CAEjB,mBAAqB,CADrB,eAEF,CAEA,6BACE,eAAgB,CAChB,mBACF,CAEA,mCACE,eACF,CAEA,6BACE,cACF","sourcesContent":[".content {\n  padding: 1.875rem 5rem 1.5rem;\n  line-height: 1.5rem;\n  margin-top: 0;\n}\n\n.aboutMainTitle {\n  margin-top: 2.375rem;\n  padding-left: 5rem;\n}\n\n.aboutTitle {\n  font-size: 1.1rem;\n  margin-top: 2rem;\n  margin-bottom: 0.5rem;\n}\n\n.subHeader {\n  height: 3.125rem;\n  padding: 0.75rem 5rem;\n}\n\n.publicationName {\n  font-weight: bold;\n}\n\n.paragraph {\n  margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "AboutView__content__1sCrR",
	"aboutMainTitle": "AboutView__aboutMainTitle__iR4PJ",
	"aboutTitle": "AboutView__aboutTitle__2wXrb",
	"subHeader": "AboutView__subHeader__1GSA4",
	"publicationName": "AboutView__publicationName__3n5Kl",
	"paragraph": "AboutView__paragraph__1h1z4"
};
export default ___CSS_LOADER_EXPORT___;
