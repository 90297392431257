import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { CollapsingModelService, CollapsingModelsService, } from 'Common/services';
import { MOST_SIGNIFICANT_ASSOCIATIONS_THRESHOLD } from 'FESView/consts';
import { findLastIndex, sortByPValue } from 'FESView/utils';
import { BinaryAssociationGLRService } from './BinaryAssociationGLR/BinaryAssociationGLR.service';
import { BinaryAssociationVLRService } from './BinaryAssociationVLR/BinaryAssociationVLR.service';
import { ContinuousAssociationGLRService } from './ContinuousAssociationGLR/ContinuousAssociationGLR.service';
import { ContinuousAssociationVLRService } from './ContinuousAssociationVLR/ContinuousAssociationVLR.service';
/**
 * Class service with static methods for work with PhenotypeAssociations
 */
export class AssociationsService {
    /**
     * Creates a collection of associations from response
     *
     * @static
     * @param data - associations data from API
     * @returns A collection of associations
     */
    static aggregateAssociations(data) {
        return data
            .reduce((acc, { associations }) => (Object.assign(Object.assign({}, acc), associations)), {});
    }
    /**
     * Creates an array of associations from associations collection
     *
     * @static
     * @param collection - associations collection
     * @returns An array of associations
     */
    static getAssociationsArray(collection, levelResults) {
        return Object.entries(collection)
            .reduce((acc, curr) => acc.concat(curr[1].map((item) => (Object.assign(Object.assign({}, item), { collapsingModelId: curr[0], levelResults })))), []);
    }
    /**
     * Returns an array of associations with unique genes which have the lowest pvalue
     * among the genes with the same names
     *
     * @static
     * @param associationsArray - associations array
     * @returns An array of associations
     */
    static getAssociationsWithUniqueGene(associationsArray) {
        const result = associationsArray.reduce((acc, curr) => {
            if (!acc[curr.gene_id]) {
                acc[curr.gene_id] = curr;
                return acc;
            }
            if (parseFloat(acc[curr.gene_id].pvalue) > parseFloat(curr.pvalue)) {
                acc[curr.gene_id] = curr;
            }
            return acc;
        }, {});
        return Object.values(result);
    }
    /**
     * Returns an array of defined size of aggregated glr and vlr associations, sorted by pvalue.
     *
     * For Internal Portal, most significant glr associations (<= 1e-4) are prioritized.
     *
     * @static
     * @param associationsGLR - GLR associations array
     * @param associationsVLR - VLR associations array
     * @returns An array of associations
     */
    static getReducedSortedAssociationsArray(associationsGLR, associationsVLR) {
        const [mostSignificantAssociationsGLR, otherAssociationsGLR] = associationsGLR
            .reduce((result, association) => {
            if (parseFloat(association.pvalue) <= MOST_SIGNIFICANT_ASSOCIATIONS_THRESHOLD) {
                result[0].push(association);
            }
            else {
                result[1].push(association);
            }
            return result;
        }, [
            [],
            [],
        ]);
        const otherAssociationsSorted = otherAssociationsGLR
            .concat(associationsVLR)
            .sort(sortByPValue);
        return mostSignificantAssociationsGLR
            .sort(sortByPValue)
            .concat(otherAssociationsSorted);
    }
    /**
     * Gets an boolean value which identify does association have GLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isGLR(association) {
        var _a, _b, _c;
        const associationVLRCandidate = association;
        const variantProp = (_c = (_b = (_a = associationVLRCandidate.variant_id) !== null && _a !== void 0 ? _a : associationVLRCandidate.variant_name) !== null && _b !== void 0 ? _b : associationVLRCandidate.variant_type) !== null && _c !== void 0 ? _c : null;
        return variantProp === null;
    }
    /**
     * Gets an boolean value which identify does association have VLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isVLR(association) {
        return !AssociationsService.isGLR(association);
    }
    /**
     * Gets min and max coordinates for X and Y axis
     * @static
     * @param association - Associations array
     * @returns The object with edge coordinates
     */
    static getEdgeCoordinates(associations, defaultEdgeCoordinates) {
        if (!associations.length) {
            return defaultEdgeCoordinates;
        }
        return associations.reduce((acc, { coordinates: { x, y } }) => {
            if (acc.maxX < x) {
                acc.maxX = x;
            }
            if (acc.minX > x) {
                acc.minX = x;
            }
            if (acc.maxY < y) {
                acc.maxY = y;
            }
            if (acc.minY > y) {
                acc.minY = y;
            }
            return acc;
        }, Object.assign({}, defaultEdgeCoordinates));
    }
    /**
     * Creates an aggregated array of phenotype associations from API
     * @static
     * @param dataGLR - Original GLR phenotype associations data received from API
     * @param dataVLR - Original VLR phenotype associations data received from API
     * @returns An array of phenotype associations
     */
    static fromResponse(dataGLR, dataVLR) {
        if (!Object.keys(dataGLR.associations).length
            && !Object.keys(dataVLR.associations).length) {
            return [];
        }
        const associationsArrayGLR = AssociationsService
            .getAssociationsArray(dataGLR.associations, ELevelResult.Gene);
        const associationsArrayVLR = AssociationsService
            .getAssociationsArray(dataVLR.associations, ELevelResult.Variant);
        const uniqueGeneAssociationsGLR = AssociationsService
            .getAssociationsWithUniqueGene(associationsArrayGLR);
        const uniqueGeneAssociationsVLR = AssociationsService
            .getAssociationsWithUniqueGene(associationsArrayVLR);
        return AssociationsService.getReducedSortedAssociationsArray(uniqueGeneAssociationsGLR, uniqueGeneAssociationsVLR);
    }
    /**
     * Creates an array of phenotype associations with plot data
     * @static
     * @param dataGLR - Original GLR phenotype associations data received from API
     * @param dataVLR - Original VLR phenotype associations data received from API
     * @param traitsType - Type of traits / associations which phenotype associations relate to
     * @param collapsingModels - Collapsing models instance
     * @param plotSize - The amount of the dots on the plot
     * @returns An array of phenotype associations
     */
    static getPlotData(dataGLR, dataVLR, traitsType, collapsingModels, plotSize) {
        const phenotypeData = {
            phenotype_id: dataGLR.phenotype_id,
            phenotype_name: dataGLR.phenotype_name,
            category_id: dataGLR.category_id,
            category_name: dataGLR.category_name,
            category_short_name: dataGLR.category_short_name,
            traits_type: dataGLR.traits_type,
        };
        const associationsArray = AssociationsService.fromResponse(dataGLR, dataVLR);
        const associationsArrayFiltered = traitsType === ETraitsTypes.Binary
            ? associationsArray
                .filter(({ stats }) => stats.odds_ratio)
            : associationsArray;
        let reducedAssociationsArray = [];
        if (associationsArrayFiltered.length > plotSize
            && associationsArrayFiltered[plotSize - 1].pvalue
                === associationsArrayFiltered[plotSize].pvalue) {
            const { pvalue: lastItemPValue, levelResults: lastItemLevelResult, } = associationsArrayFiltered[plotSize - 1];
            const isLastItemGlr = lastItemLevelResult === ELevelResult.Gene;
            const isLastItemsPValueBelowThreshold = (Number(lastItemPValue) <= MOST_SIGNIFICANT_ASSOCIATIONS_THRESHOLD);
            const lastIndexOfThresholdPValue = isLastItemGlr && isLastItemsPValueBelowThreshold
                ? (findLastIndex(associationsArrayFiltered, (association) => (association.levelResults === ELevelResult.Gene && association.pvalue === lastItemPValue))) : (findLastIndex(associationsArrayFiltered, (association) => (association.pvalue === lastItemPValue)));
            reducedAssociationsArray = associationsArrayFiltered.slice(0, lastIndexOfThresholdPValue + 1);
        }
        else {
            reducedAssociationsArray = associationsArrayFiltered.slice(0, plotSize);
        }
        if (traitsType === ETraitsTypes.Binary) {
            return AssociationsService.getBinaryPlotData(reducedAssociationsArray, phenotypeData, collapsingModels);
        }
        return AssociationsService.getContinuousPlotData(reducedAssociationsArray, phenotypeData, collapsingModels);
    }
    /**
     * Transform phenotype associations to binary AssociationsGLR array
     * @static
     * @param associations - Collection of phenotype associations form API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModels - Collapsing models instance
     * @returns Array of binary AssociationsGLR
     */
    static getBinaryPlotData(associations, phenotypeData, collapsingModels) {
        return associations.map((association) => {
            var _a;
            const levelResult = AssociationsService.isGLR(association)
                ? ELevelResult.Gene
                : ELevelResult.Variant;
            const collapsingModel = (_a = CollapsingModelsService.getCollapsingModelById(collapsingModels, association.collapsingModelId)) !== null && _a !== void 0 ? _a : CollapsingModelService.empty;
            if (levelResult === ELevelResult.Gene) {
                return BinaryAssociationGLRService.create(association, phenotypeData, collapsingModel);
            }
            return BinaryAssociationVLRService.create(association, phenotypeData, collapsingModel);
        }).filter((association) => !!association.oddsRatio);
    }
    /**
     * Transform phenotype associations to continuous AssociationsGLR array
     * @static
     * @param associations - Collection of phenotype associations form API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModels - Collapsing models instance
     * @returns Array of continuous AssociationsGLR
     */
    static getContinuousPlotData(associations, phenotypeData, collapsingModels) {
        return associations.map((association) => {
            var _a;
            const levelResult = AssociationsService.isGLR(association)
                ? ELevelResult.Gene
                : ELevelResult.Variant;
            const collapsingModel = (_a = CollapsingModelsService.getCollapsingModelById(collapsingModels, association.collapsingModelId)) !== null && _a !== void 0 ? _a : CollapsingModelService.empty;
            if (levelResult === ELevelResult.Gene) {
                return ContinuousAssociationGLRService.create(association, phenotypeData, collapsingModel);
            }
            return ContinuousAssociationVLRService.create(association, phenotypeData, collapsingModel);
        });
    }
    /**
     * Filters the array of phenotype associations to avoid those that have MAF or controlMAF
     * greater than 0.5
     * @static
     * @param plotData - An array of phenotype associations
     * @returns An array of phenotype associations filtered
     */
    static filterAssociations(plotData) {
        return plotData.filter((pd) => {
            var _a, _b;
            const data = pd;
            if (data.traitsType === ETraitsTypes.Continuous) {
                const point = pd;
                return ((_a = point.AAF) !== null && _a !== void 0 ? _a : 0) <= 0.5;
            }
            if (data.traitsType === ETraitsTypes.Binary) {
                const point = pd;
                return ((_b = point.controlAaf) !== null && _b !== void 0 ? _b : 0) <= 0.5;
            }
            return true;
        });
    }
}
