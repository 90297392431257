import { createSlice } from '@reduxjs/toolkit';
import { ETraitsTypes } from 'Shared/types';
import { logger } from 'Common/utils/logger/logger';
import { QuantilesService } from 'Common/services/Quantiles';
import { CollapsingModelsService, TraitsTypeService } from 'Common/services';
import { ELoadingStatus } from 'API/types';
import { getMaxPlotValues } from './utils';
import { getInitialState } from './initialState';
import { fetchQQAPIData, setQQDataLoadingMessage } from './actions/fetchQQAPIData';
import { fetchAPIData } from './actions/fetchAPIData';
import { setLoadingMessage } from './actions/common';
export const initialState = getInitialState();
const qqView = createSlice({
    name: 'qqView',
    initialState,
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        cleanup(state) {
            const { dataExists, loadingStatus, loadingMessage, errorType, phenotype, traitsType, lambda, plotData, maxPlotValues, phenotypeStatistic, collapsingModels, } = initialState;
            state.dataExists = dataExists;
            state.loadingMessage = loadingMessage;
            state.loadingStatus = loadingStatus;
            state.errorType = errorType;
            state.phenotype = phenotype;
            state.traitsType = traitsType;
            state.lambda = lambda;
            state.plotData = plotData;
            state.maxPlotValues = maxPlotValues;
            state.phenotypeStatistic = phenotypeStatistic;
            state.collapsingModels = collapsingModels;
        },
        cleanupQQData(state) {
            const { QQDataErrorType, QQDataLoadingStatus, QQDataLoadingMessage, lambda, plotData, maxPlotValues, } = initialState;
            state.plotData = plotData;
            state.maxPlotValues = maxPlotValues;
            state.lambda = lambda;
            state.QQDataErrorType = QQDataErrorType;
            state.QQDataLoadingStatus = QQDataLoadingStatus;
            state.QQDataLoadingMessage = QQDataLoadingMessage;
        },
        setCollapsingModels(state, action) {
            state.collapsingModels = CollapsingModelsService.createCollection(action.payload);
        },
        setPhenotypeStatistic(state, action) {
            state.phenotypeStatistic = {
                cases: action.payload.cases,
                controls: action.payload.controls,
                participants: action.payload.participants,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLoadingMessage, (state, action) => {
            state.loadingMessage = action.payload;
        })
            .addCase(setQQDataLoadingMessage, (state, action) => {
            state.QQDataLoadingMessage = action.payload;
        })
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchAPIData.fulfilled, (state) => {
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addCase(fetchQQAPIData.pending, (state) => {
            state.QQDataErrorType = null;
            state.QQDataLoadingStatus = ELoadingStatus.Loading;
            state.QQDataLoadingMessage = 'Loading QQ data...';
        })
            .addCase(fetchQQAPIData.rejected, (state, { payload, meta }) => {
            if (meta.aborted) {
                return;
            }
            const { type: errorType } = payload;
            state.QQDataErrorType = errorType;
            state.QQDataLoadingStatus = ELoadingStatus.Finished;
        })
            .addCase(fetchQQAPIData.fulfilled, (state, { payload }) => {
            state.QQDataLoadingMessage = '';
            state.QQDataLoadingStatus = ELoadingStatus.Finished;
            const { phenotype_id: phenotypeId, phenotype_name: phenotypeName, trait: traitsType, min_p_observed: lowestPValue = 0, min_p_perm: lowestPermPValue = 0, lambda_value: lambda, quantiles, model_id: collapsingModelId, model_name: collapsingModelName, } = payload.qqData;
            if (!(phenotypeId && phenotypeName && collapsingModelId && collapsingModelName)) {
                logger.warn('Received empty QQ data from QQ service!');
                return;
            }
            const traits = TraitsTypeService.create(traitsType, ETraitsTypes.Continuous);
            state.traitsType = traits;
            state.lambda = lambda;
            state.phenotype = {
                id: phenotypeId,
                name: phenotypeName,
            };
            state.maxPlotValues = getMaxPlotValues(lowestPermPValue, lowestPValue);
            state.plotData = QuantilesService.getPlotData(quantiles);
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup, setCollapsingModels, setPhenotypeStatistic, cleanupQQData, } = qqView.actions;
export default qqView.reducer;
