import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import { ErrorBoundary, Loading } from 'Shared/components';
import { DEFAULT_LOADING_LABEL } from 'Common/consts';
import ErrorPlaceholder from 'Common/components/ErrorPlaceholder/ErrorPlaceholder';
import CacheHandler from 'Common/components/CacheHandler';
import { logger } from 'Common/utils';
import { SEARCH_PATH, NOT_FOUND_PATH, SERVER_ERROR_PATH, PHENOTYPE_VIEW_PATHS_ARRAY, QV_VIEW_PATH, ABOUT_VIEW_PATH, GENE_VIEW_PATHS_ARRAY, VARIANT_VIEW_PATHS_ARRAY, VARIANT_DASHBOARD_VIEW_PATH, QQ_VIEW_PATH, FES_VIEW_PATH, GENE_DASHBOARD_VIEW_PATH, MODEL_DEFINITIONS_PATH, PHENOTYPE_CATALOGUE_VIEW_PATH, } from 'Router/index';
import { NotFoundPage } from 'ErrorPages/NotFoundPage/NotFoundPage';
import { ServerErrorPage } from 'ErrorPages/ServerErrorPage/ServerErrorPage';
import SearchPage from './views/search';
import AboutPage from './views/about';
import './App.css';
/* eslint-disable comma-dangle */
const GeneViewPage = lazy(() => import(
/* webpackChunkName: "gv.chunk" */
'./views/gene'));
const PhenotypeViewPage = lazy(() => import(
/* webpackChunkName: "pv.chunk" */
'./views/phenotype'));
const PhenotypeCatalogueViewPage = lazy(() => import(
/* webpackChunkName: "pv.chunk" */
'./views/phenotypeCatalogue'));
const VariantViewPage = lazy(() => import(
/* webpackChunkName: "vv.chunk" */
'./views/variant'));
const QVViewPage = lazy(() => import(
/* webpackChunkName: "qv.chunk" */
'./views/qv'));
const QQViewPage = lazy(() => import(
/* webpackChunkName: "qq.chunk" */
'./views/qq'));
const FESViewPage = lazy(() => import(
/* webpackChunkName: "fes.chunk" */
'./views/fes'));
const GeneDashboardViewPage = lazy(() => import(
/* webpackChunkName: "geneDashboard.chunk" */
'./views/geneDashboard'));
const PlaceholderPage = lazy(() => import(
/* webpackChunkName: "placeholder.chunk" */
'./views/placeholder'));
const VariantDashboardViewPage = lazy(() => import(
/* webpackChunkName: "variantDashboard.chunk" */
'./views/variantDashboard'));
const ModelDefinitionsPage = lazy(() => import(
/* webpackChunkName: "modelDefinitions.chunk" */
'./views/modelDefinitions'));
const App = () => {
    const routes = MAINTENANCE_PLACEHOLDER.TIMER
        ? (_jsx(Routes, { children: _jsx(Route, { path: "/", element: (_jsx(PlaceholderPage, { endDate: MAINTENANCE_PLACEHOLDER.TIMER, text: MAINTENANCE_PLACEHOLDER.TEXT, autoExtension: MAINTENANCE_PLACEHOLDER.AUTO_EXTENSION }, void 0)) }, void 0) }, void 0))
        : (_jsxs(Routes, { children: [_jsx(Route, { path: SEARCH_PATH, element: _jsx(SearchPage, {}, void 0) }, void 0), GENE_VIEW_PATHS_ARRAY.map((path) => (_jsx(Route, { path: path, element: _jsx(GeneViewPage, {}, void 0) }, path))), FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED
                    && !FEATURE_FLAGS.PUBLIC_VERSION
                    && _jsx(Route, { path: GENE_DASHBOARD_VIEW_PATH, element: _jsx(GeneDashboardViewPage, {}, void 0) }, void 0), FEATURE_FLAGS.QQ_PLOT_ENABLED && _jsx(Route, { path: QQ_VIEW_PATH, element: _jsx(QQViewPage, {}, void 0) }, void 0), FEATURE_FLAGS.FES_PLOT_ENABLED
                    && (_jsx(Route, { path: FES_VIEW_PATH, element: _jsx(FESViewPage, {}, void 0) }, void 0)), PHENOTYPE_VIEW_PATHS_ARRAY.map((path) => (_jsx(Route, { path: path, element: _jsx(PhenotypeViewPage, {}, void 0) }, path))), FEATURE_FLAGS.PHENOTYPE_CATALOGUE_ENABLED
                    && (_jsx(Route, { path: PHENOTYPE_CATALOGUE_VIEW_PATH, element: _jsx(PhenotypeCatalogueViewPage, {}, void 0) }, void 0)), VARIANT_VIEW_PATHS_ARRAY.map((path) => (_jsx(Route, { path: path, element: _jsx(VariantViewPage, {}, void 0) }, path))), FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED
                    && !FEATURE_FLAGS.PUBLIC_VERSION
                    && FEATURE_FLAGS.VARIANT_DASHBOARD_ENABLED
                    && _jsx(Route, { path: VARIANT_DASHBOARD_VIEW_PATH, element: _jsx(VariantDashboardViewPage, {}, void 0) }, void 0), _jsx(Route, { path: QV_VIEW_PATH, element: _jsx(QVViewPage, {}, void 0) }, void 0), _jsx(Route, { path: ABOUT_VIEW_PATH, element: _jsx(AboutPage, {}, void 0) }, void 0), _jsx(Route, { path: NOT_FOUND_PATH, element: _jsx(NotFoundPage, {}, void 0) }, void 0), _jsx(Route, { path: SERVER_ERROR_PATH, element: _jsx(ServerErrorPage, {}, void 0) }, void 0), _jsx(Route, { path: MODEL_DEFINITIONS_PATH, element: _jsx(ModelDefinitionsPage, {}, void 0) }, void 0), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: NOT_FOUND_PATH }, void 0) }, void 0)] }, void 0));
    return (_jsxs(_Fragment, { children: [_jsx(CacheHandler, {}, void 0), _jsx(Router, { children: _jsx(ErrorBoundary, Object.assign({ logger: logger, errorPlaceholder: _jsx(ErrorPlaceholder, { message: "Application failed to load resources required for rendering the page" }, void 0) }, { children: _jsx(Suspense, Object.assign({ fallback: (_jsx(Loading, { message: "Loading assets...", label: DEFAULT_LOADING_LABEL }, void 0)) }, { children: routes }), void 0) }), void 0) }, void 0)] }, void 0));
};
export default hot(App);
