import { datasetVersionIdMock } from 'Common/mocks';
import { createMockedURL, getMock } from 'API/utils';
import resource from './resource';
export const response = {
    transcript_id: 'ENST00000232892',
    mtr_scores: [
        1.0275164375061137, 0.629281703171976, 0.4313336408063084, 0.827113810800607,
        0.6077466553893999, 0.9042224479588618, 1.3760930461745318, 1.1091701037479549,
        0.768562815597347, 1.0914449750553854, 1.0500423682566562, 0.5125925601773639,
        1.2419289739763066, 0.3833825778309413, 0.42231932645971104, 0.7911122840416617,
        1.3677277833806651, 1.3212741420784004, 0.4089716620789968, 0.521220264219439,
        0.6878044738120567, 1.0885917984693607, 0.5618858770284585, 0.09561806252950555,
        1.1203434955098956, 0.9407935849739196, 1.0212170532543405, 0.7587630560536293,
        null, null, null, null,
        1.3240122684142732, 0.3615280564265042, 0.5964681610036532, 1.1410912164146727,
        1.4690650760616475, 0.8373510923240862, 0.215399858011034, 0.6381795602520485,
        0.6143549910809232, 0.44779895830821215, 0.4101309263817293, 1.200146693682104,
        0.3237590331755189, 0.06980340589065448, 0.2976084452011649, 1.2011037398145121,
        null, null,
    ],
};
export const emptyResponse = {
    transcript_id: '',
    mtr_scores: [],
};
export const requestParams = {
    datasetVersionId: datasetVersionIdMock,
};
const mockedURL = {
    [createMockedURL(resource, requestParams)]: {
        full: response,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);
